import DrawerNotes from "@app/common/components/DrawerNotes";
import { warningNotification } from "@app/common/components/notifications";
import { Template } from "@app/common/components/Template";
import { fileToBase64 } from "@app/common/utils/utils";
import routes from "@app/config/routes";
import {
  useGetSupportDetailDispensary,
  useUpdateSupportFile,
} from "@app/services/SupportsConsult/hooks";
import { filesExtension, FileSupportDispensary } from "@app/services/SupportsConsult/types";
import { calculateAge } from "@app/utils/generateId";
import { Badge, Box, Button, Card, FileButton, Grid, Table, Text } from "@mantine/core";
import { IconPaperclip, IconTrendingUp3, IconUpload } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const DetailSupportsConsults = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [trigger, setTrigger] = useState(0);
  const [openDrawer, setOpenDrawer] = useState({
    isOpen: false,
  });

  const [documentSelected, setDocumentSelected] = useState<{
    url: string | null;
    id: number | null;
  }>({
    url: null,
    id: null,
  });
  const { data: detailSupport } = useGetSupportDetailDispensary({
    id: location?.state?.id,
    trigger,
  });

  useEffect(() => {
    if (detailSupport?.results?.files && detailSupport?.results?.files?.length > 0) {
      const document = detailSupport?.results?.files.find(
        file => file.rdsId === documentSelected.id,
      );
      if (document) {
        setDocumentSelected({
          url: document.url,
          id: document.rdsId,
        });
      }
    }
  }, [detailSupport]);

  const updateSupportRequest = useUpdateSupportFile(async () => {
    const doAfter = () => {
      setTrigger(trigger + 1);
    };

    await doAfter();
  });

  const handleChangeFile = async (
    file: File | null,
    typeAccepted: string,
    typeByMessage: string,
    id: number,
  ) => {
    if (file && typeAccepted.includes(file?.type)) {
      const base64 = await fileToBase64(file);
      await updateSupportRequest.mutateAsync({
        file: base64,
        disId: Number(detailSupport?.results.disId),
        rdsId: id,
      });
    } else {
      warningNotification(
        `Los tipos de documentos permitidos son: ${typeByMessage.replace(",", ", ")}`,
      );
    }
  };

  const renderRow = (option: FileSupportDispensary) => {
    const isDisabled =
      option.status.value === "reject" || option.status.value === "pending" ? false : true;
    const isSelected = option.rdsId === documentSelected.id;
    const hasSupport = option.url === "null" ? false : true;
    const date = option?.createdAt?.split("T")[0];
    const time = option?.createdAt?.split("T")[1];
    const extensionsAccepted = (option.files_extension as filesExtension[])
      .map(extension => extension.fil_mime_type)
      .join(",");
    return (
      <Table.Tr
        key={option.rdsId}
        bg={isSelected ? "rgba(253, 126, 20, 0.1)" : ""}
        className="pointer"
        onClick={() => {
          setDocumentSelected(state => ({ ...state, url: option.url, id: option.rdsId }));
        }}
      >
        <Table.Td>{option.supportName}</Table.Td>
        <Table.Td ta={"center"}>
          {date} {time}
        </Table.Td>
        <Table.Td ta={"center"}>
          <Badge variant="light" color={option.status.fontColor} w={"100%"}>
            {option.status.description}
          </Badge>
        </Table.Td>
        <Table.Td ta={"end"} className="flex gap-2 justify-end">
          {hasSupport ? (
            <div className="flex flex-column gap-1 justify-end items-end">
              <FileButton
                data-testid="uploadButton"
                onChange={e => {
                  handleChangeFile(
                    e,
                    extensionsAccepted,
                    String((option?.files_extension as filesExtension[])?.map(ext => ext.fil_name)),
                    Number(option.rdsId),
                  );
                }}
                accept={"pdf"}
              >
                {props => (
                  <IconPaperclip
                    {...props}
                    className={`${isDisabled ? "disabled" : "pointer"}`}
                    style={{ width: "25px", height: "30px" }}
                  />
                )}
              </FileButton>
            </div>
          ) : (
            <div className="flex justify-end">
              <FileButton
                data-testid="uploadButton"
                onChange={e => {
                  handleChangeFile(e, "application/pdf", "pdf", Number(option.rdsId));
                }}
                accept={"pdf"}
              >
                {props => (
                  <IconUpload style={{ width: "25px", height: "30px" }} {...props}>
                    Upload image
                  </IconUpload>
                )}
              </FileButton>
            </div>
          )}

          <Box
            className={`message-icon ${option.numberComments > 0 ? "" : "message-icon-disabled"}  flex justify-center items-center`}
            mt={4}
            onClick={() => setOpenDrawer({ isOpen: true })}
          >
            <Box pt={2}>{option.numberComments}</Box>
          </Box>
        </Table.Td>
      </Table.Tr>
    );
  };

  const renderTable = () => {
    return (
      <Table mt={"sm"} mb="sm" className="biowel-table">
        <Table.Thead>
          <Table.Tr>
            <Table.Th ta={"start"}>Documento</Table.Th>
            <Table.Th ta={"center"}>Fecha adjunto</Table.Th>
            <Table.Th ta={"center"}>Estado</Table.Th>
            <Table.Th ta={"end"}></Table.Th>
          </Table.Tr>
        </Table.Thead>

        <Table.Tbody>
          {detailSupport?.results?.files && detailSupport?.results?.files?.length > 0 ? (
            detailSupport?.results?.files?.map(renderRow)
          ) : (
            <Table.Tr>
              <Table.Td ta="center" colSpan={12}>
                No hay información para mostrar.
              </Table.Td>
            </Table.Tr>
          )}
        </Table.Tbody>
      </Table>
    );
  };

  return (
    <Template
      title="Detalle documentos"
      goBackButton={() => {
        navigate(routes.getSupportsConsult);
      }}
    >
      <DrawerNotes
        id={Number(documentSelected.id)}
        isOpen={openDrawer.isOpen}
        trigger={trigger}
        setTrigger={setTrigger}
        onClose={() => setOpenDrawer({ isOpen: false })}
      />
      <Box>
        <Text fz="md" ml="xs" flex={1} c="brand-color" fw={"bold"}>
          Información del paciente
        </Text>
        <Card mb={"md"} padding="lg" bg={"#e1faff71"} radius="md" __size="xl" className="">
          <Grid>
            <Grid.Col span={2.4}>
              <Text fz="xs" ml="xs" flex={1} fw={"inherit"}>
                Nombre paciente
              </Text>
              <Text fz="xs" ml="xs" flex={1} c="secondary-color" fw={"bold"}>
                {detailSupport?.results?.patient?.name ?? "No hay información"}
              </Text>
            </Grid.Col>
            <Grid.Col span={2.4}>
              <Text fz="xs" ml="xs" flex={1} fw={"inherit"}>
                Identificación
              </Text>
              <Text fz="xs" ml="xs" flex={1} c="secondary-color" fw={"bold"}>
                {detailSupport?.results?.patient?.documentType}
                {detailSupport?.results?.patient?.document ?? "No hay información"}
              </Text>
            </Grid.Col>
            <Grid.Col span={2.4}>
              <Text fz="xs" ml="xs" flex={1} fw={"inherit"}>
                Edad
              </Text>
              <Text fz="xs" ml="xs" flex={1} c="secondary-color" fw={"bold"}>
                {calculateAge(detailSupport?.results?.patient?.birthDate ?? "No hay información")}{" "}
                años
              </Text>
            </Grid.Col>
            <Grid.Col span={2.4}>
              <Text fz="xs" ml="xs" flex={1} fw={"inherit"}>
                Empresa cliente
              </Text>
              <Text fz="xs" ml="xs" flex={1} c="secondary-color" fw={"bold"}>
                {detailSupport?.results?.corporateClient ?? "No hay información"}
              </Text>
            </Grid.Col>
            <Grid.Col span={2.4}>
              <Text fz="xs" ml="xs" flex={1} fw={"inherit"}>
                Contrato
              </Text>
              <Text fz="xs" ml="xs" flex={1} c="secondary-color" fw={"bold"}>
                {detailSupport?.results?.contract ?? "No hay información"}
              </Text>
            </Grid.Col>
            <Grid.Col span={2.4}>
              <Text fz="xs" ml="xs" flex={1} fw={"inherit"}>
                Nivel
              </Text>
              <Text fz="xs" ml="xs" flex={1} c="secondary-color" fw={"bold"}>
                {detailSupport?.results?.level ?? "No hay información"}
              </Text>
            </Grid.Col>
            <Grid.Col span={2.4}>
              <Text fz="xs" ml="xs" flex={1} fw={"inherit"}>
                Tipo de afiliado
              </Text>
              <Text fz="xs" ml="xs" flex={1} c="secondary-color" fw={"bold"}>
                {detailSupport?.results?.patient?.affiliationType ?? "No hay información"}
              </Text>
            </Grid.Col>
            <Grid.Col span={2.4}>
              <Text fz="xs" ml="xs" flex={1} fw={"inherit"}>
                Población
              </Text>
              <Text fz="xs" ml="xs" flex={1} c="secondary-color" fw={"bold"}>
                {detailSupport?.results?.population ?? "No hay información"}
              </Text>
            </Grid.Col>
          </Grid>
        </Card>
      </Box>
      <Box h={"80%"}>
        {documentSelected.url && (
          <Text fz="xs" ml="xs" flex={1} fw={"inherit"} py={"md"}>
            Registrado por: "No hay información" | Última modificación: "No hay información"
          </Text>
        )}
        <Grid h={"95%"} className="flex ">
          <Grid.Col span={6} className="flex justify-center" h={"100%"}>
            {documentSelected.url ? (
              <iframe
                title="document"
                src={documentSelected.url}
                style={{ width: "100%", height: "100%" }}
                frameBorder="0"
              />
            ) : (
              <Box className="flex  items-center" h={"100%"}>
                <Text fz="h4" ml="xs" flex={1} fw={"inherit"} py={"md"} className="flex gap-2">
                  Seleccione un documento para visualizarlo
                  <IconTrendingUp3 style={{ width: 30, height: 30 }} />
                </Text>
              </Box>
            )}
          </Grid.Col>
          <Grid.Col span={6} pl={"md"}>
            {renderTable()}
            <Box className="flex justify-end gap-3">
              <Button
                variant="outline"
                data-testid="gobackCancelButton"
                onClick={() => navigate(routes.getSupportsConsult)}
              >
                Cancelar
              </Button>
              <Button
                variant="filled"
                data-testid="approvedJustification"
                onClick={() => warningNotification("Funcionalidad en desarrollo")}
              >
                Guardar
              </Button>
            </Box>
          </Grid.Col>
        </Grid>
      </Box>
    </Template>
  );
};

export default DetailSupportsConsults;
