import { useDeliveryData } from "@app/store/useDeliverySearchData";
import { Box, TextInput, Title } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { Fragment } from "react/jsx-runtime";
import SelectionDelivery from "../../selection/Selection";
import OrderPatientList from "./OrderPatientList";
import "./styles.scss";

const PatientOrderInformation = () => {
  const navigate = useNavigate();
  const { showDeliveryBy } = useDeliveryData(state => state.dataDispense);

  const { dataPatient: patientData } = useDeliveryData(state => state.dataToSearch);

  const searchSection = () => {
    return (
      <>
        <div className="searchSection">
          <Box>
            <Box px={"lg"} pt={"xl"} mt={"sm"} pb={"xs"} className="">
              <div className="flex items-center pointer" onClick={() => navigate(-1)}></div>
              <Title size={"h3"} py={"xs"} c="secondary-color" fw={"bold"} ta={"center"}>
                Buscar ordenamiento
              </Title>
            </Box>
            <Box px={"lg"}>
              <div className="">
                <TextInput
                  label="Identificación del paciente"
                  placeholder="Escribir..."
                  value={`${patientData?.docTypeDesc} ${patientData?.document}`}
                  disabled
                />
                <TextInput
                  label="Primer Nombre"
                  placeholder="Escribir..."
                  value={patientData?.first_name}
                  disabled
                />
                <TextInput
                  label="Primer apellido"
                  value={patientData?.last_name}
                  placeholder="Seleccionar..."
                  disabled
                />
                <TextInput
                  label="Fecha de nacimiento"
                  type="date"
                  placeholder="Escribir..."
                  value={patientData?.birth_date}
                  disabled
                />
                <TextInput
                  label="Whatsapp"
                  value={patientData?.whatsapp}
                  placeholder="Escribir..."
                  disabled
                />
                <TextInput
                  label="Correo"
                  value={patientData?.email}
                  placeholder="Escribir..."
                  disabled
                />
              </div>
            </Box>
          </Box>
        </div>
      </>
    );
  };

  const renderComponent = () => {
    return (
      <Fragment>
        <div className="bodyOrder">
          {searchSection()}

          {showDeliveryBy ? (
            <SelectionDelivery />
          ) : (
            <div className="renderSection">
              <OrderPatientList />
            </div>
          )}
        </div>
      </Fragment>
    );
  };

  return renderComponent();
};

export default PatientOrderInformation;
