import { useEffect, useMemo, useState } from "react";

import { formattedDataSelect } from "@app/common/utils/utils";
import { GCidAccount } from "@app/config/globalsConst";
import { useGetBankEntities, useGetDepositType, useGetFranchise } from "@app/services/common/hooks";
import { generateId } from "@app/utils/generateId";
import {
  Box,
  Button,
  Card,
  Grid,
  NumberFormatter,
  NumberInput,
  Select,
  Text,
  TextInput,
} from "@mantine/core";
import {
  IconCardsFilled,
  IconCashBanknote,
  IconCashRegister,
  IconCirclePlus,
  IconCreditCard,
  IconWallet,
  IconX,
} from "@tabler/icons-react";
import { IDeposit, IDepositState, PaymentMethods } from "./depositComponent";

const icons: PaymentMethods = {
  transfer: <IconCashRegister style={{ width: 30, height: 30 }} />,
  cash: <IconWallet style={{ width: 30, height: 30 }} />,
  card: <IconCardsFilled style={{ width: 30, height: 30 }} />,
  debit_note: <IconCashBanknote style={{ width: 30, height: 30 }} />,
  credit_note: <IconCreditCard style={{ width: 30, height: 30 }} />,
  wallet: <IconWallet style={{ width: 30, height: 30 }} />,
};

const YES_NO_OPTIONS = [
  { label: "Seleccionar...", value: "" },
  { label: "Sí", value: "1" },
  { label: "No", value: "0" },
];

const INITIAL_STATE = {
  depositTypeId: "",
  amount: "",
  pendingAmount: "",
  type: "default",
  bankId: null,
  franchiseId: null,
  referenceNumber: "",
  donate: null,
  donationValue: "",
};

export default function DepositComponent({ payment = 0, onChange, walletAmount }: IDeposit) {
  const [depositList, setDepositList] = useState<IDepositState[]>([
    { ...INITIAL_STATE, id: generateId() },
  ]);

  const {
    data: depositTypesList = {
      results: [],
      rowTotal: 0,
    },
  } = useGetDepositType({ eaccount: GCidAccount() });

  const {
    data: bankList = {
      results: [],
      rowTotal: 0,
    },
    refetch: refetchBankList,
  } = useGetBankEntities({ eaccount: GCidAccount() });

  const {
    data: franchiseList = {
      results: [],
      rowTotal: 0,
    },
    refetch: refetchFranchise,
  } = useGetFranchise({ eaccount: GCidAccount() });

  const depositTypes = useMemo(() => {
    if (depositTypesList?.results) {
      return [
        ...formattedDataSelect(depositTypesList.results, "depoTypeName", "depoTypeLabel", [
          "depoTypeId",
        ]).filter(type => type.value !== "wallet"),
      ];
    }
    return [];
  }, [depositTypesList?.results]);

  type IOptionItem = (typeof depositTypes)[number] & { depoTypeId?: string };

  const banks = formattedDataSelect(bankList.results, "description", "id");
  const franchises = formattedDataSelect(franchiseList.results, "fraName", "fraId");

  const totalPayment = useMemo(() => {
    if (depositList.length) {
      return depositList.reduce((acc, el) => {
        return acc + (isNaN(Number(el.amount)) ? 0 : Number(el.amount));
      }, 0);
    }
    return 0;
  }, [depositList]);

  useEffect(() => {
    const walletType = depositTypesList?.results?.find(dep => dep.depoTypeLabel === "wallet");

    if (walletAmount && walletType) {
      if (depositList.some(dep => dep.type === "wallet")) {
        setDepositList(state => {
          const newState = state.map(dep => {
            if (Number(dep.depositTypeId) === Number(walletType.depoTypeId)) {
              return { ...dep, amount: walletAmount };
            }
            return dep;
          });
          onChange(newState);
          return newState;
        });
      } else {
        const newState = [
          {
            ...INITIAL_STATE,
            id: generateId(),
            depositTypeId: walletType.depoTypeId,
            type: walletType.depoTypeLabel,
            amount: walletAmount ?? "",
          },
        ];
        onChange(newState);
        setDepositList(newState);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [depositTypesList?.results, walletAmount]);

  const onAddNewDeposit = () => {
    const options = [...depositList, { ...INITIAL_STATE, id: generateId() }];
    setDepositList(options);
    onChange(options);
  };

  const onRemoveDeposit = (id: string) => {
    const options = depositList.filter(deposit => deposit.id !== id);
    setDepositList(options);
    onChange(options);
  };

  const onChangeDepositType = (option: { id: string; type: string; deposit: IDepositState }) => {
    if (option.type === "transfer" || option.type === "card") {
      refetchBankList();
    }

    if (option.type === "card") {
      refetchFranchise();
    }

    const options = depositList.map(dep => {
      if (dep.id === option.deposit.id) {
        return { id: dep.id, ...INITIAL_STATE, type: option.type, depositTypeId: option.id };
      }
      return dep;
    });
    setDepositList(options);
    onChange(options);
  };

  const onChangeBank = (bankId: number, depositId: string) => {
    const options = depositList.map(dep => {
      if (dep.id === depositId) {
        return { ...dep, bankId };
      }
      return dep;
    });
    setDepositList(options);
    onChange(options);
  };

  const onChangeFranchise = (franchiseId: number, depositId: string) => {
    const options = depositList.map(dep => {
      if (dep.id === depositId) {
        return { ...dep, franchiseId };
      }
      return dep;
    });
    setDepositList(options);
    onChange(options);
  };

  const onChangeAmount = (value: number, deposit: IDepositState) => {
    const options = depositList.map(dep => {
      if (dep.id === deposit.id) {
        return {
          ...dep,
          amount: value,
          pendingAmount:
            deposit.type === "cash" && value > payment
              ? value - payment - (Number(deposit?.donationValue) ?? 0)
              : "",
          returnedChange: "",
          donate: null,
          donationValue: "",
        };
      }
      return dep;
    });
    setDepositList(options);
    onChange(options);
  };

  const onChangeDonation = (value: string, depositId: string) => {
    const options = depositList.map(dep => {
      if (dep.id === depositId) {
        return { ...dep, donate: value, donationValue: "" };
      }
      return dep;
    });
    setDepositList(options);
    onChange(options);
  };

  const onChangeReturnedChange = (value: string, deposit: IDepositState) => {
    const options = depositList.map(dep => {
      if (dep.id === deposit.id) {
        return {
          ...dep,
          returnedChange: value,
        };
      }
      return dep;
    });
    setDepositList(options);
    onChange(options);
  };

  const onChangeDonationValue = (value: string, deposit: IDepositState) => {
    const options = depositList.map(dep => {
      if (dep.id === deposit.id) {
        return {
          ...dep,
          donationValue: value,
          pendingAmount: Number(deposit?.amount) - payment - (Number(value) ?? 0),
        };
      }
      return dep;
    });
    setDepositList(options);
    onChange(options);
  };

  const onChangeRefNum = (value: string, depositId: string) => {
    const options = depositList.map(dep => {
      if (dep.id === depositId) {
        return { ...dep, referenceNumber: value };
      }
      return dep;
    });
    setDepositList(options);
    onChange(options);
  };

  const renderDefaultType = () => {
    return (
      <>
        <Grid.Col pb={0} span={6} className="self-end">
          <TextInput className=" w-100" disabled={true} label={` `} />
        </Grid.Col>
        <Grid.Col pb={0} span={6} className="self-end">
          <TextInput className=" w-100" disabled={true} label={` `} />
        </Grid.Col>
        <Grid.Col pb={0} span={6} className="self-end">
          <TextInput className=" w-100" disabled={true} label={` `} />
        </Grid.Col>
      </>
    );
  };

  const renderWalletType = (deposit: IDepositState) => {
    return (
      <Grid.Col pb={0} span={6} className="self-end">
        <NumberInput
          label="Monto pagado"
          required
          className=" w-100"
          allowNegative={false}
          placeholder="Escribe..."
          decimalScale={0}
          prefix="$"
          thousandSeparator={","}
          decimalSeparator={"."}
          allowLeadingZeros={false}
          value={deposit?.amount}
          disabled
        />
      </Grid.Col>
    );
  };

  const renderTransferType = (deposit: IDepositState) => {
    return (
      <>
        <Grid.Col pb={0} span={6} className="self-end">
          <NumberInput
            label="Monto pagado"
            required
            className=" w-100"
            allowNegative={false}
            placeholder="Escribe..."
            decimalScale={0}
            prefix="$"
            thousandSeparator={","}
            decimalSeparator={"."}
            allowLeadingZeros={false}
            value={deposit?.amount}
            onValueChange={({ floatValue }) => {
              const value = floatValue ?? 0;
              void onChangeAmount(value, deposit);
            }}
            isAllowed={({ floatValue }) => {
              const value = floatValue ?? 0;
              return value <= payment;
            }}
          />
        </Grid.Col>
        <Grid.Col pb={0} span={6}>
          <Select
            className="text-secondary"
            label="Banco"
            required
            placeholder={"Seleccionar..."}
            data={banks}
            value={banks.find(bank => Number(bank.value) === deposit?.bankId)?.value}
            onChange={option => {
              onChangeBank(Number(option), deposit.id);
            }}
          />
        </Grid.Col>
        <Grid.Col pb={0} span={6} className="self-end">
          <NumberInput
            label="No. referencia"
            required
            className=" w-100"
            allowNegative={false}
            placeholder="Escribe..."
            decimalScale={0}
            allowLeadingZeros
            value={deposit?.referenceNumber}
            onValueChange={({ value }) => {
              void onChangeRefNum(value, deposit.id);
            }}
          />
        </Grid.Col>
      </>
    );
  };

  const renderCashType = (deposit: IDepositState) => {
    return (
      <>
        <Grid.Col pb={0} span={6} className="self-end">
          <NumberInput
            label="Monto pagado"
            required
            className=" w-100"
            allowNegative={false}
            placeholder="Escribe..."
            decimalScale={0}
            prefix="$"
            thousandSeparator={","}
            decimalSeparator={"."}
            allowLeadingZeros={false}
            value={deposit?.amount}
            onValueChange={({ floatValue }) => {
              const value = floatValue ?? 0;
              void onChangeAmount(value, deposit);
            }}
          />
        </Grid.Col>
        <Grid.Col pb={0} span={4} className=" pt-2">
          <small className={`secondary-color ms-1 d-block`}>Vuelto total</small>
          <NumberInput
            disabled
            className=" w-100"
            allowNegative={false}
            placeholder="Escribe..."
            decimalScale={0}
            prefix="$"
            thousandSeparator={","}
            decimalSeparator={"."}
            allowLeadingZeros={false}
            value={Number(deposit?.amount) > payment ? Number(deposit?.pendingAmount) : 0}
          />
        </Grid.Col>
        {Number(deposit?.amount) > payment && (
          <>
            <Grid.Col pb={0} span={4}>
              <small className={`secondary-color ms-1 d-block`}>¿Donar vuelto?</small>
              <Select
                className="text-secondary"
                placeholder={"Seleccionar..."}
                value={YES_NO_OPTIONS.find(opt => opt.value === deposit?.donate)?.value}
                data={YES_NO_OPTIONS}
                onChange={option => {
                  onChangeDonation(option ?? "", deposit.id);
                }}
              />
            </Grid.Col>
            {!!deposit?.donate && (
              <Grid.Col pb={0} span={4}>
                <small className={`secondary-color ms-1 d-block`}>¿Cuánto?</small>
                <NumberInput
                  className=" w-100"
                  allowNegative={false}
                  decimalScale={0}
                  prefix="$"
                  thousandSeparator={","}
                  decimalSeparator={"."}
                  allowLeadingZeros={false}
                  value={deposit?.donationValue}
                  onValueChange={({ floatValue }) => {
                    void onChangeDonationValue(String(floatValue), deposit);
                  }}
                  isAllowed={({ floatValue }) => {
                    const value = floatValue ?? 0;
                    return (
                      value <=
                      Number(deposit?.amount) - payment - (Number(deposit?.donationValue) ?? 0)
                    );
                  }}
                />
              </Grid.Col>
            )}
            <Grid.Col pb={0} span={4}>
              <small className={`secondary-color ms-1 d-block`}>¿Vuelto entregado?</small>
              <Select
                className="text-secondary"
                placeholder={"Seleccionar..."}
                data={YES_NO_OPTIONS}
                value={YES_NO_OPTIONS.find(opt => opt.value === deposit?.returnedChange)?.value}
                onChange={option => {
                  onChangeReturnedChange(option ?? "", deposit);
                }}
              />
            </Grid.Col>
          </>
        )}
      </>
    );
  };

  const renderCardType = (deposit: IDepositState) => {
    return (
      <>
        <Grid.Col pb={0} span={6} className="self-end">
          <NumberInput
            label="Monto pagado"
            required
            className=" w-100"
            allowNegative={false}
            placeholder="Escribe..."
            decimalScale={0}
            prefix="$"
            thousandSeparator={","}
            decimalSeparator={"."}
            allowLeadingZeros={false}
            value={deposit?.amount}
            onValueChange={({ floatValue }) => {
              const value = floatValue ?? 0;
              void onChangeAmount(value, deposit);
            }}
            isAllowed={({ floatValue }) => {
              const value = floatValue ?? 0;
              return value <= payment;
            }}
          />
        </Grid.Col>
        <Grid.Col pb={0} span={4}>
          <Select
            label="Banco"
            required
            className="text-secondary"
            placeholder={"Seleccionar..."}
            data={banks}
            value={banks.find(bank => Number(bank.value) === deposit?.bankId)?.value}
            onChange={option => {
              onChangeBank(Number(option), deposit.id);
            }}
          />
        </Grid.Col>
        <Grid.Col pb={0} span={4}>
          <Select
            label="Franquicia"
            required
            className="text-secondary"
            placeholder={"Seleccionar..."}
            data={franchises}
            value={franchises.find(fra => Number(fra.value) === deposit?.franchiseId)?.value}
            onChange={option => {
              onChangeFranchise(Number(option), deposit.id);
            }}
          />
        </Grid.Col>
        <Grid.Col pb={0} span={4} className="self-end">
          <NumberInput
            label="No. referencia"
            required
            className=" w-100"
            allowNegative={false}
            placeholder="Escribe..."
            decimalScale={0}
            allowLeadingZeros
            value={deposit?.referenceNumber}
            onValueChange={({ value }) => {
              void onChangeRefNum(value, deposit.id);
            }}
          />
        </Grid.Col>
      </>
    );
  };

  const renderNoteType = (deposit: IDepositState) => {
    return (
      <Grid.Col pb={0} span={6} className="self-end">
        <NumberInput
          label="Monto pagado"
          required
          className=" w-100"
          allowNegative={false}
          placeholder="Escribe..."
          decimalScale={0}
          prefix="$"
          thousandSeparator={","}
          decimalSeparator={"."}
          allowLeadingZeros={false}
          value={deposit?.amount}
          onValueChange={({ floatValue }) => {
            const value = floatValue ?? 0;
            void onChangeAmount(value, deposit);
          }}
          isAllowed={({ floatValue }) => {
            const value = floatValue ?? 0;
            return value <= payment;
          }}
        />
      </Grid.Col>
    );
  };

  const renderComponent = () => {
    return (
      <Box ml={"xs"}>
        {depositList.map(deposit => (
          <Card mb={"xs"}>
            <Box key={deposit.id} pos={"relative"} my={"xs"} p={"xs"}>
              {depositList.length > 1 && (
                <Box
                  pos={"absolute"}
                  className="position-absolute"
                  style={{ right: 10, top: 5 }}
                  onClick={() => onRemoveDeposit(deposit.id)}
                >
                  <IconX className="pointer" />
                </Box>
              )}
              <div className="flex w-100">
                <Card style={{ minWidth: 100 }} mr={"lg"}>
                  <Box p={"xs"} className={` h-100 p-3 flex items-center justify-center`}>
                    {icons[deposit.type]}
                  </Box>
                </Card>
                <Grid className="w-100 items-end">
                  <Grid.Col pb={0} span={6}>
                    <Select
                      className="text-secondary"
                      label="Tipo de recaudo"
                      required
                      placeholder={"Seleccionar..."}
                      data={
                        deposit.type === "wallet"
                          ? [
                              {
                                label: "Bolsillo",
                                value: "wallet",
                              },
                            ]
                          : depositTypes
                      }
                      value={
                        formattedDataSelect(
                          depositTypesList.results,
                          "depoTypeName",
                          "depoTypeLabel",
                          ["depoTypeId"],
                        )?.find(type => type.value === deposit.type)?.value
                      }
                      disabled={deposit.type === "wallet"}
                      onChange={(_, value: IOptionItem) => {
                        onChangeDepositType({
                          id: value.depoTypeId ?? "",
                          type: value.value,
                          deposit,
                        });
                      }}
                    />
                  </Grid.Col>
                  {deposit.type === "default" && renderDefaultType()}
                  {deposit.type === "transfer" && renderTransferType(deposit)}
                  {deposit.type === "cash" && renderCashType(deposit)}
                  {deposit.type === "card" && renderCardType(deposit)}
                  {deposit.type === "debit_note" && renderNoteType(deposit)}
                  {deposit.type === "credit_note" && renderNoteType(deposit)}
                  {deposit.type === "wallet" && renderWalletType(deposit)}
                </Grid>
              </div>
            </Box>
          </Card>
        ))}
        <Box px={""} className="flex items-center ">
          {payment - totalPayment > 0 && (
            <Button className={""} onClick={onAddNewDeposit}>
              <b className={`text-muted me-2`}>Agregar otro recaudo</b> &nbsp;
              <IconCirclePlus className="pointer " />
            </Button>
          )}

          <Box
            p={"sm"}
            className={` gap-3 flex ${Number(totalPayment) < payment ? "danger-color" : "primary-color"}`}
          >
            <b>Faltante</b>

            <Text
              fz="md"
              flex={1}
              c={` ${Number(totalPayment) < payment ? "danger-color" : "primary-color"}`}
              fw={"bold"}
            >
              <NumberFormatter
                prefix="$ "
                value={Number(totalPayment) < payment ? payment - totalPayment : 0}
                thousandSeparator
              />{" "}
            </Text>
          </Box>
        </Box>
      </Box>
    );
  };

  return renderComponent();
}
