import { warningNotification } from "@app/common/components/notifications";
import { fileToBase64 } from "@app/common/utils/utils";
import { FileAdditionalSupports } from "@app/services/drugDelivery/types";
import { useGetSupportParamsList } from "@app/services/supportParams/hooks";
import { Extension, Support } from "@app/services/supportParams/types";
import { Box, Button, FileButton, Modal, ScrollArea, Table } from "@mantine/core";
import { IconCircleCheck, IconTrash, IconUpload } from "@tabler/icons-react";
import { useEffect, useState } from "react";

export default function DeliverySupportModal({
  onClose,
  open,
  contractId,
  handleChange,
  data,
}: {
  onClose: () => void;
  data: FileAdditionalSupports[];
  contractId: number;
  open: boolean;
  handleChange: (files: FileAdditionalSupports[]) => void;
}) {
  const [files, setFiles] = useState<FileAdditionalSupports[]>(data.length > 0 ? data : []);
  const {
    data: supportList = {
      results: [],
      rowTotal: 0,
    },
  } = useGetSupportParamsList({
    contractId,
  });

  useEffect(() => {
    if (data.length > 0) {
      setFiles(data);
    } else {
      const dataRes = supportList.results.at(0)?.supports.map(supports => ({
        rcsId: Number(supports.id),
        file: null,
        filename: "",
      }));
      if (dataRes && dataRes?.length > 0) {
        setFiles(dataRes);
      }
    }
  }, [supportList, data]);

  const closeModal = () => {
    setFiles([]);
    onClose();
  };

  const handleChangeFile = async (
    file: File | null,
    typeAccepted: string,
    typeByMessage: string,
    id: number,
  ) => {
    if (file && typeAccepted.includes(file?.type)) {
      const base64 = await fileToBase64(file);
      const data = files.map(x =>
        x.rcsId === id ? { ...x, file: base64, filename: file.name } : x,
      );
      setFiles(data);
    } else {
      warningNotification(
        `Los tipos de documentos permitidos son: ${typeByMessage.replace(",", ", ")}`,
      );
    }
  };

  const deleteFile = (id: number) => {
    const data = files.map(x => (x.rcsId === id ? { ...x, file: null } : x));
    setFiles(data);
  };

  const renderRow = (option: Support) => {
    const hasSupport = files.some(file => file.rcsId === option.id && file.file);
    const filename = files.find(file => file.rcsId === option.id && file.file)?.filename;
    const extensionsAccepted = (option.extensions as Extension[])
      .map(extension => extension.mimetype)
      .join(",");

    return (
      <Table.Tr key={option.id}>
        <Table.Td>
          <div className={`flex flex-column ${hasSupport && "gap-1"}`}>
            <div>{option.filename}</div>
            <div style={{ width: "25rem" }}>
              <span className="breakEllipsis" title={filename}>
                <b>{filename}</b>
              </span>
            </div>
          </div>
        </Table.Td>
        <Table.Td>
          {hasSupport ? (
            <div className="flex flex-column gap-1 justify-end items-end">
              <IconCircleCheck color="#7bc62d" size={"30px"} />
              <IconTrash
                className="pointer"
                data-testid="deleteFile"
                onClick={() => deleteFile(Number(option.id ?? ""))}
              />
            </div>
          ) : (
            <div className="flex justify-end">
              <FileButton
                data-testid="uploadButton"
                onChange={e => {
                  handleChangeFile(
                    e,
                    extensionsAccepted,
                    String((option.extensions as Extension[]).map(ext => ext.extension)),
                    Number(option.id),
                  );
                }}
                accept={extensionsAccepted}
              >
                {props => (
                  <IconUpload className="pointer" {...props}>
                    Upload image
                  </IconUpload>
                )}
              </FileButton>
            </div>
          )}
        </Table.Td>
      </Table.Tr>
    );
  };

  const renderTable = () => {
    return (
      <Table mt={"sm"} mb="sm" className="biowel-table" key={files.length}>
        <Table.Thead>
          <Table.Tr>
            <Table.Th ta={"start"}>Documentos</Table.Th>
            <Table.Th></Table.Th>
          </Table.Tr>
        </Table.Thead>

        <Table.Tbody>
          {supportList?.results?.at(0)?.supports?.length ? (
            supportList?.results?.at(0)?.supports?.map(renderRow)
          ) : (
            <Table.Tr>
              <Table.Td ta="center" colSpan={12}>
                No hay información para mostrar.
              </Table.Td>
            </Table.Tr>
          )}
        </Table.Tbody>
      </Table>
    );
  };

  return (
    <Modal
      size={500}
      title="Soportes para dispensación"
      opened={open}
      onClose={closeModal}
      scrollAreaComponent={ScrollArea.Autosize}
    >
      <Box>{renderTable()}</Box>
      <Box className="flex justify-end gap-3">
        <Button variant="outline" data-testid="cancel" onClick={closeModal}>
          Cancelar
        </Button>
        <Button
          variant="filled"
          data-testid="saveFiles"
          disabled={!files.some(file => file.file !== null)}
          onClick={() => {
            handleChange(files);
            closeModal();
          }}
        >
          Guardar
        </Button>
      </Box>
    </Modal>
  );
}
