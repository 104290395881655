import AppPagination from "@app/common/components/AppPagination";
import { formattedDataSelect } from "@app/common/utils/utils";
import { GCidAccount } from "@app/config/globalsConst";
import routes from "@app/config/routes";
import { useGetDoctorList, useGetSiteList } from "@app/services/common/hooks";
import { useGetOrdersList } from "@app/services/drugDelivery/hooks";
import { OrderListFilters } from "@app/services/drugDelivery/types";
import { useDeliveryData } from "@app/store/useDeliverySearchData";
import { Grid, Select, Table, TextInput } from "@mantine/core";
import { useDebouncedState } from "@mantine/hooks";
import { IconChevronRight } from "@tabler/icons-react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Fragment } from "react/jsx-runtime";

interface Props {
  type: "corpClient" | "patient";
  corporateClient: number | string;
}

const InsuranceList = ({ type, corporateClient }: Props) => {
  const setShowDelivery = useDeliveryData(store => store.setDataDispense);

  const navigate = useNavigate();

  const [filters, setFilters] = useDebouncedState<OrderListFilters>(
    {
      type,
      endDate: "",
      startDate: "",
      patient: "",
      doctor: "",
      site: "",
      corporateClient,
      page: 1,
    },
    500,
  );

  const {
    data: ordersList = {
      results: [],
      rowTotal: 0,
    },
  } = useGetOrdersList({
    ...filters,
    perpage: 10,
  });

  const {
    data: doctorList = {
      results: [],
      rowTotal: 0,
    },
  } = useGetDoctorList({
    eaccount: GCidAccount(),
  });

  const {
    data: sitesList = {
      results: [],
      rowTotal: 0,
    },
  } = useGetSiteList({
    eaccount: GCidAccount(),
    modality: "on_site",
    status: "enabled",
  });

  useEffect(() => {
    setFilters(state => ({ ...state, type, corporateClient }));
  }, [type, corporateClient, setFilters]);

  const FiltersHeader = () => {
    return (
      <Grid align="flex-end" mb="lg" w="100%">
        <Grid.Col span={3}>
          <TextInput
            data-testid="startDate"
            label="Fecha desde"
            type="date"
            placeholder="Escribir..."
            onChange={({ target }) => {
              setFilters(state => ({
                ...state,
                startDate: target.value,
              }));
            }}
          />
        </Grid.Col>
        <Grid.Col span={3}>
          <TextInput
            data-testid="endDate"
            label="Fecha hasta"
            type="date"
            placeholder="Escribir..."
            onChange={({ target }) => {
              setFilters(state => ({
                ...state,
                endDate: target.value,
              }));
            }}
          />
        </Grid.Col>
        <Grid.Col span={3}>
          <Select
            data-testid="site"
            label="Sede"
            data={formattedDataSelect(sitesList.results, "site", "siteId")}
            searchable
            placeholder="Seleccionar..."
            onChange={value => {
              setFilters(state => ({
                ...state,
                site: value ?? "",
              }));
            }}
          />
        </Grid.Col>
        <Grid.Col span={3}>
          <Select
            data-testid="doctor"
            label="Médico"
            searchable
            data={formattedDataSelect(doctorList.results, "mdName", "mdId")}
            placeholder="Seleccionar..."
            onChange={value => {
              setFilters(state => ({
                ...state,
                doctor: value ?? "",
              }));
            }}
          />
        </Grid.Col>
      </Grid>
    );
  };

  const renderRow = (option: (typeof ordersList.results)[number]) => {
    const date = (option?.createdAtMedicalOrders ?? "").split(" | ")[0].replaceAll("/", "-");
    return (
      <Table.Tr key={option?.orderId}>
        <Table.Td>{option?.site}</Table.Td>

        <Table.Td>{option?.doctor}</Table.Td>
        <Table.Td>
          <b>{option?.patient?.name}</b>
        </Table.Td>
        <Table.Td>
          {option?.patient?.documentType} {option?.patient?.document}
        </Table.Td>
        <Table.Td ta={"center"}>{date}</Table.Td>
        <Table.Td ta={"center"}>{option?.validUntil}</Table.Td>
        <Table.Td ta="end">
          <div
            className="pointer"
            onClick={() => {
              if (option.medicalOrderType === "insurance") {
                setShowDelivery({ showDeliveryBy: true, orderId: Number(option.orderId) });
              } else {
                setShowDelivery({
                  orderId: Number(option.orderId),
                });
                navigate(routes.getDeliveryDispensation);
              }
            }}
          >
            <IconChevronRight />
          </div>
        </Table.Td>
      </Table.Tr>
    );
  };

  const renderTable = () => {
    return (
      <Table className="biowel-table">
        <Table.Thead>
          <Table.Tr>
            <Table.Th ta={"start"}>Sede</Table.Th>
            <Table.Th>Médico</Table.Th>
            <Table.Th>Paciente</Table.Th>
            <Table.Th ta={"start"}>Identificación</Table.Th>
            <Table.Th ta={"center"}>Fecha orden</Table.Th>
            <Table.Th ta={"center"}>Fecha Validez</Table.Th>
            <Table.Th></Table.Th>
          </Table.Tr>
        </Table.Thead>

        <Table.Tbody>
          {ordersList?.results?.length ? (
            ordersList?.results?.map(renderRow)
          ) : (
            <Table.Tr>
              <Table.Td ta="center" colSpan={12}>
                No hay información para mostrar.
              </Table.Td>
            </Table.Tr>
          )}
        </Table.Tbody>
      </Table>
    );
  };
  const renderComponent = () => {
    return (
      <Fragment>
        {FiltersHeader()}
        {renderTable()}

        <AppPagination
          total={ordersList?.rowTotal || 0}
          value={filters.page || 1}
          onChange={value => setFilters(state => ({ ...state, page: value }))}
        />
      </Fragment>
    );
  };

  return <div className="main-container">{renderComponent()}</div>;
};

export default InsuranceList;
