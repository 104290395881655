import routes from "@app/config/routes";
import { useDeliveryData } from "@app/store/useDeliverySearchData";
import { Box, Card, Flex, Group, Text, Title, Tooltip } from "@mantine/core";
import { IconChevronLeft, IconMedicineSyrup, IconPills } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import "./styles.scss";

export default function SelectionDelivery() {
  const navigate = useNavigate();

  const { setDataDispense } = useDeliveryData();

  const renderComponent = () => {
    return (
      <div className="main-container ">
        <Flex
          mih={50}
          w={"100%"}
          gap="md"
          justify="start"
          align="center"
          direction="column"
          wrap="wrap"
          p={"xl"}
        >
          <Box className="self-start">
            <Tooltip label="Regresar" withArrow position="right">
              <IconChevronLeft
                data-testid="goBackButton"
                style={{ width: 35, height: 35 }}
                className="primary-color pointer "
                onClick={() => {
                  setDataDispense({ showDeliveryBy: false, orderId: null });
                }}
              />
            </Tooltip>
          </Box>
          <Title mb="sm" size={"h2"} c="secondary-color" fw={"bold"}>
            Entrega de medicamentos
          </Title>
          <Group px="xl" justify="space-around">
            <Card
              key={"insurance"}
              shadow="sm"
              padding="lg"
              radius="md"
              __size="xl"
              w={250}
              onClick={() => navigate(routes.getDeliveryDispensation)}
              className="card"
            >
              <div className="flex-column justify-center items-center cardSpace">
                <div className="pinColor">
                  <IconMedicineSyrup
                    style={{
                      width: 30,
                      height: 30,
                    }}
                    className="primary-color whiteHover"
                  />
                </div>
                <Text size="sm" mt={"sm"} fw="bold" className="primary-color ">
                  Aseguradora
                </Text>
              </div>
            </Card>
            <Card
              key={"particular"}
              shadow="sm"
              padding="lg"
              radius="md"
              __size="xl"
              w={250}
              onClick={() => navigate(routes.getDeliveryDispensation)}
              className="card"
            >
              <div className="flex-column justify-center items-center cardSpace">
                <div className="pinColor">
                  <IconPills
                    style={{
                      width: 30,
                      height: 30,
                    }}
                    className="primary-color"
                  />
                </div>
                <Text size="sm" mt={"sm"} fw="bold" className="primary-color">
                  Venta libre
                </Text>
              </div>
            </Card>
          </Group>
        </Flex>
      </div>
    );
  };

  return renderComponent();
}
