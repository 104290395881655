import DepositComponent from "@app/common/components/depositComponent/DepositComponent";
import { IDepositState } from "@app/common/components/depositComponent/depositComponent";
import routes from "@app/config/routes";
import { useDeliveryDispense, useGetPatientPrice } from "@app/services/drugDelivery/hooks";
import {
  DeliveryPayload,
  MedicinePay,
  OrderListResponse,
  pricePatientPayload,
} from "@app/services/drugDelivery/types";
import { Box, Button, Drawer, NumberFormatter, Table, Text } from "@mantine/core";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const SetDelivery = ({
  isOpen,
  onClose,
  data,
}: {
  isOpen: boolean;
  data?: OrderListResponse;
  onClose: () => void;
}) => {
  const navigate = useNavigate();

  const [collection, setCollection] = useState<IDepositState[]>([]);
  console.log("collection: ", collection);

  const [prices, setPrices] = useState({
    companyPrice: 0,
    patientPrice: 0,
  });

  const closeDrawer = () => {
    onClose();
  };

  const createPriceRequest = useGetPatientPrice(async res => {
    const doAfter = () => {
      setPrices(res.results as { companyPrice: number; patientPrice: number });
    };

    await doAfter();
  });

  const onSubmitPricePatient = async (value: pricePatientPayload) => {
    await createPriceRequest.mutateAsync(value);
  };

  useEffect(() => {
    if (isOpen && data?.orderInfo?.orderId && data?.orderInfo?.patient.id && data?.medicines) {
      onSubmitPricePatient({
        orderId: data?.orderInfo?.orderId,
        patientId: data?.orderInfo?.patient.id,
        medicines: data?.medicines.map(medicine => ({
          medId: medicine.id ?? "",
          qty: medicine.qtyDispensed ?? "",
        })),
      });
    }
  }, [data?.medicines, data?.orderInfo, isOpen]);

  const createDeliveryRequest = useDeliveryDispense(async res => {
    const doAfter = () => {
      closeDrawer();
      navigate(routes.getDeliveryDispensationDetail, {
        state: { disId: res.results, orderInfo: data?.orderInfo },
      });
    };

    await doAfter();
  });

  const onChangeDeposit = (deposits: IDepositState[]) => {
    setCollection(deposits);
  };

  const onSubmit = async (value: DeliveryPayload) => {
    await createDeliveryRequest.mutateAsync(value);
  };

  const onSubmitValidations = () => {
    onSubmit({
      additionalSupports: data?.additionalSupports
        ?.filter(x => x.file !== null)
        ?.map(supports => ({
          file: supports.file ?? "",
          rcsId: supports.rcsId,
        })),
      disFacePicture: data?.patientFacePicture,
      disRequestByName: data?.receiveName,
      disRequestByDocumentId: data?.receiveDocument,
      disSignature: data?.patientSignature,
      morId: Number(data?.orderInfo?.orderId),
      patientDocumentPictures: data?.patientDocumentPictures,
      disRequestByDocumentType: Number(data?.disRequestByDocumentType),
      medicines: data?.medicines?.map(medicine => ({
        companyPrice: prices.companyPrice ?? "",
        id: medicine.id ?? "",
        patientPrice: prices.patientPrice ?? "",
        presentationId: medicine.presentationId ?? "",
        qtyDelivered: medicine.qtyDispensed ?? "",
        qtyDispensed: medicine.qtyDelivered ?? "",
        qtyOrder: medicine.qtyOrder ?? "",
      })),
    });
  };

  const renderRow = (option: MedicinePay) => {
    return (
      <Table.Tr key={option?.id}>
        <Table.Td>{option?.name}</Table.Td>
        <Table.Td>{option?.description}</Table.Td>
        <Table.Td ta={"center"}>{option?.qtyDispensed ?? "-"} unds.</Table.Td>
      </Table.Tr>
    );
  };

  const renderTable = (medicines: MedicinePay[]) => {
    return (
      <Table className="biowel-table" my={"sm"} highlightOnHover={false} verticalSpacing={"0.3rem"}>
        <Table.Thead>
          <Table.Tr>
            <Table.Th bg={"#fff"} ta={"start"} className="secondary-color">
              Referencia
            </Table.Th>
            <Table.Th bg={"#fff"} ta={"start"} className="secondary-color">
              Descripción
            </Table.Th>
            <Table.Th bg={"#fff"} ta={"center"} className="secondary-color">
              Cantidad
            </Table.Th>
          </Table.Tr>
        </Table.Thead>

        <Table.Tbody>
          {medicines ? (
            <>
              {medicines?.map(renderRow)}
              <Table.Tr>
                <Table.Td>
                  {" "}
                  <Text fz="md" pt={"sm"} flex={1} c="brand-color" fw={"bold"}>
                    Cuota moderadora
                  </Text>
                </Table.Td>
                <Table.Td></Table.Td>
                <Table.Td ta={"center"}>
                  <Text fz="md" pt={"sm"} flex={1} c="brand-color" fw={"bold"}>
                    <NumberFormatter prefix="$ " value={prices?.patientPrice} thousandSeparator />
                  </Text>
                </Table.Td>
              </Table.Tr>
            </>
          ) : (
            <Table.Tr>
              <Table.Td ta="center" colSpan={12}>
                No hay información para mostrar.
              </Table.Td>
            </Table.Tr>
          )}
        </Table.Tbody>
      </Table>
    );
  };

  return (
    <Drawer opened={isOpen} onClose={closeDrawer} title={"Resumen de compra "} size={"lg"}>
      <Box className="flex flex-column justify-between" h={"100%"}>
        <Box>
          <Text fz="md" pt={"sm"} ml="xs" flex={1} c="brand-color" fw={"bold"}>
            Selecciona el método de pago
          </Text>
          {/* {collection.length > 0 &&
            collection.map(item => {
              return collectionCard(item);
            })}

          <Button
            variant="subtle"
            rightSection={<IconCirclePlus />}
            onClick={() => addCollection()}
          >
            Agregar otro recaudo
          </Button> */}
          <DepositComponent payment={400000} onChange={onChangeDeposit} walletAmount={0} />
          <Text fz="md" pt={"sm"} ml="xs" flex={1} c="brand-color" fw={"bold"}>
            Resumen de entrega
          </Text>
          {renderTable(
            data?.medicines?.map(medicine => ({
              companyPrice: prices.companyPrice ?? "",
              id: medicine.id ?? "",
              name: medicine.name ?? "",
              description: medicine.description ?? "",
              patientPrice: prices.patientPrice ?? "",
              presentationId: medicine.presentationId ?? "",
              qtyDelivered: medicine.qtyDelivered ?? "",
              qtyDispensed: medicine.qtyDispensed ?? "",
              qtyOrder: medicine.qtyOrder ?? "",
            })) ?? [],
          )}
        </Box>

        <Box pb={"md"} className="flex justify-end gap-3">
          <Button variant="outline" onClick={closeDrawer} data-testid="cancelFullRequest">
            Cancelar
          </Button>
          <Button
            variant="filled"
            onClick={() => onSubmitValidations()}
            data-testid="onSubmitFullRequest"
          >
            {" "}
            Generar resumen de dispensación
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
};

export default SetDelivery;
