import { PatientList } from "@app/services/common/types";
import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

interface IDataToSearch {
  type: "patient" | "corpClient";
  patient: string;
  corporateClient: string | number;
  dataPatient?: PatientList | null;
}

interface IData {
  showDeliveryBy?: boolean;
  orderId?: number | null;
  action?: string;
}

interface SearchData {
  dataToSearch: IDataToSearch;
  dataDispense: IData;
  setDataDispense: (payload: IData) => void;
  saveDataToSearch: (_payload: IDataToSearch) => void;
  clearDataDispense: () => void;
}

const initialState: IDataToSearch = {
  type: "patient",
  patient: "",
  corporateClient: "",
  dataPatient: null,
};

const initialStateData: IData = {
  showDeliveryBy: false,
  orderId: null,
  action: "",
};
/**
 * Zustand hook for authentication management
 */
export const useDeliveryData = create<SearchData>()(
  devtools(
    persist(
      set => ({
        dataToSearch: initialState,
        dataDispense: initialStateData,
        saveDataToSearch: payload => {
          set(state => ({ ...state, dataToSearch: payload }));
        },
        setDataDispense: payload => {
          set(state => ({ ...state, dataDispense: payload }));
        },
        clearDataDispense: () => {
          set(state => ({ ...state, dataDispense: initialStateData, dataToSearch: initialState }));
        },
      }),
      {
        name: "data-delivery-storage",
      },
    ),
  ),
);
