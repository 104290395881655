import { generateId } from "@app/utils/generateId";
import { Pill, PillsInput } from "@mantine/core";
import { forwardRef, useState } from "react";

interface IMulti {
  onChange: (data: string[]) => void;
  label: string;
  placeholder: string;
  className?: string;
  required?: boolean;
}

export const MultiInput = forwardRef<HTMLInputElement, IMulti>(function TextField(
  { onChange, label, placeholder, required, className, ...rest },
  ref,
) {
  const [pills, setPills] = useState<{ data: string; id: string }[]>([]);

  const [data, setData] = useState("");

  const addPill = () => {
    setPills(state => [...state, { data, id: generateId() }]);
    onChange([...pills, { data, id: generateId() }].map(x => x.data));
    setData("");
  };

  const deletePill = (id: string) => {
    const filter = pills.filter(x => x.id !== id);
    setPills(filter);
    onChange(filter.map(x => x.data));
  };

  return (
    <PillsInput
      required={required}
      label={label}
      ref={ref}
      size="xs"
      className={`${className} secondary-color`}
    >
      <Pill.Group>
        {pills.map(p => {
          return (
            <Pill key={p.id}>
              {p.data}
              <span
                className="pointer "
                onClick={() => {
                  deletePill(p.id);
                }}
              >
                &nbsp; x
              </span>
            </Pill>
          );
        })}
        <PillsInput.Field
          placeholder={placeholder}
          onChange={e => {
            setData(e.target.value);
          }}
          className={`${className} secondary-color`}
          value={data}
          onKeyDown={e => {
            if (e.key === "Enter") {
              addPill();
            }
          }}
          {...rest}
        />
      </Pill.Group>
    </PillsInput>
  );
});
