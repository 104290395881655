const routes = {
  root: "/",
  validate: "/validate",
  dispensation: "/dispensation",

  get drugDelivery() {
    return `${this.dispensation}/drugDelivery`;
  },
  get epsRoute() {
    return `${this.drugDelivery}/eps`;
  },
  get particularRoute() {
    return `${this.drugDelivery}/particular`;
  },

  get getEpsOrder() {
    return `${this.epsRoute}/order`;
  },

  get getDeliveryDispensation() {
    return `${this.drugDelivery}/dispensary`;
  },
  get getDeliveryDispensationDetail() {
    return `${this.getDeliveryDispensation}/detail`;
  },
  get getPatientOrderInformation() {
    return `${this.drugDelivery}/patientOrderInformation`;
  },
  get getDeliveryAuth() {
    return `${this.dispensation}/deliveryAuth`;
  },
  get getDeliveryAuthDetail() {
    return `${this.getDeliveryAuth}/detail`;
  },
  get getDeliveryDetailOrder() {
    return `${this.getDeliveryDispensation}/detailOrder`;
  },
  get getSupportsParameter() {
    return `${this.dispensation}/supportParameters`;
  },
  get getCreateSupportParameter() {
    return `${this.getSupportsParameter}/create`;
  },
  get getEditSupportParameter() {
    return `${this.getSupportsParameter}/edit`;
  },
  get getSupportsConsult() {
    return `${this.dispensation}/dispensarySupports`;
  },
  get getSupportsConsultDetail() {
    return `${this.getSupportsConsult}/detail`;
  },
  get getDispensarySupportFiles() {
    return `${this.dispensation}/dispensarySupportFiles`;
  },
  get getDispensaryStore() {
    return `${this.dispensation}/store`;
  },
  get getDispensarySupportFilesDetail() {
    return `${this.getDispensarySupportFiles}/detail`;
  },
  get getTariffManual() {
    return `${this.dispensation}/tariffManual`;
  },
  get getTariffManualCreate() {
    return `${this.getTariffManual}/create`;
  },
  get getPendingConsults() {
    return `${this.dispensation}/pendingConsults`;
  },
  get getPharmacyControl() {
    return `${this.dispensation}/pharmacyControl`;
  },
  get getPharmacyControlDetail() {
    return `${this.getPharmacyControl}/detail`;
  },
  get getConsumerOutReport() {
    return `${this.dispensation}/consumerOutReport`;
  },
};

export default Object.freeze(routes);
