import DownloadTemplate from "@app/common/components/DownloadTemplate";
import { confirmNotification } from "@app/common/components/notifications";
import { formatTimeToAmPm } from "@app/common/utils/utils";
import routes from "@app/config/routes";
import { useGetMedicationOrder, useUpdateAuthDelivery } from "@app/services/deliveryAuth/hooks";
import { Box, Button } from "@mantine/core";
import { IconCheck, IconX } from "@tabler/icons-react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import JustificationModal from "../../common/components/JustificationModal";

const DeliveryAuthDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [justificationModal, setJustificationModal] = useState<{
    isOpen: boolean;
  }>({
    isOpen: false,
  });

  const goBack = () => {
    navigate(routes.getDeliveryAuth);
  };

  const date = (location?.state?.dataAuth?.createdAt ?? "")?.split("T")[0]?.replaceAll("/", "-");

  const time = (location?.state?.dataAuth?.createdAt ?? "")?.split("T")[1]?.replaceAll("/", "-");
  const {
    data: pdfOrder = {
      results: "",
    },
  } = useGetMedicationOrder({
    id: location.state.data,
  });

  const updateAuth = useUpdateAuthDelivery(async () => {
    const doAfter = () => {
      goBack();
    };

    await doAfter();
  });

  const openJustificationModal = () => {
    setJustificationModal({ isOpen: true });
  };

  const handleRejectAuth = (value: string) => {
    onChangeStatus(0, value);
  };

  const onChangeStatus = async (approved: 1 | 0, justification?: string) => {
    if (approved === 1) {
      await updateAuth.mutateAsync({
        approved,
        id: location?.state?.dataAuth?.rcdId as number,
      });
    } else {
      await updateAuth.mutateAsync({
        approved,
        id: location?.state?.dataAuth?.rcdId,
        rcdRejectReason: justification ?? "",
      });
    }
  };

  return (
    <DownloadTemplate
      goBackButton={() => {
        goBack();
      }}
      url={pdfOrder.results.replace(/^b'/gm, "").replace(/=*'$/gm, "") as string}
      urlType="base64"
      title="Detalle de fórmula de medicamentos"
    >
      <Box ml={"lg"}>
        <JustificationModal
          isOpen={justificationModal.isOpen}
          handleChange={handleRejectAuth}
          onClose={() => {
            setJustificationModal({
              isOpen: false,
            });
          }}
        />
        <Box className="cardSearch">
          <b className="secondary-color">Fecha de solicitud: &nbsp;</b>
          <span>
            {date} {formatTimeToAmPm(time)}{" "}
          </span>
          <hr />
          <div>
            <b className="primary-color">Motivo de solicitud</b>
          </div>
          <div>
            <span>{location?.state?.dataAuth?.rcdReason} </span>
          </div>
        </Box>
        <Box className=" flex justify-around" w={"100%"}>
          <div>
            <Button
              variant="outline"
              color="green"
              px={"xs"}
              w={"7.5rem"}
              data-testid="approved"
              onClick={() => {
                confirmNotification("Verifique la información del usuario", "¿Está seguro?", () => {
                  onChangeStatus(1);
                });
              }}
            >
              Aprobar &nbsp; <IconCheck />
            </Button>
          </div>
          <div>
            <Button
              variant="outline"
              color="red"
              w={"7.5rem"}
              px={"xs"}
              data-testid="reject"
              onClick={() => {
                confirmNotification(
                  "Se rechazará la solicitud",
                  "¿Está seguro?",

                  () => {
                    openJustificationModal();
                  },
                );
              }}
            >
              Rechazar &nbsp; <IconX />
            </Button>
          </div>
        </Box>
      </Box>
    </DownloadTemplate>
  );
};

export default DeliveryAuthDetail;
