import { Box, Text } from "@mantine/core";
import { IconChevronLeft } from "@tabler/icons-react";

interface TemplateProps extends React.HTMLAttributes<HTMLElement> {
  goBackButton?: () => void;
  title: string;
  subtitle?: string;
}

export function Template({ children, title, subtitle, goBackButton, ...rest }: TemplateProps) {
  return (
    <Box className="full" {...rest}>
      <Box className="flex flex-column items-start">
        <Box className="flex items-center">
          {goBackButton && (
            <IconChevronLeft
              data-testid="goBackButton"
              style={{ width: 35, height: 35 }}
              className="primary-color pointer"
              onClick={goBackButton}
            />
          )}
          <Text fz="h1" ml="xs" flex={1} size={"h1"} c="secondary-color" fw={"bold"}>
            {title}
          </Text>
        </Box>
        <Box>
          {subtitle && (
            <Text fz="h6" ml="xl" pl={"md"} flex={1} c="secondary-color" fw={"inherit"}>
              {subtitle}
            </Text>
          )}
        </Box>
      </Box>

      <Box px={"sm"} h={"90%"} className="">
        {children}
      </Box>
    </Box>
  );
}
