import { Template } from "@app/common/components/Template";
import { TextField } from "@app/common/components/TextField";
import { IFiltersPagination } from "@app/common/types/http";
import { dateValidation, formattedDataSelect, formatTimeToAmPm } from "@app/common/utils/utils";
import routes from "@app/config/routes";
import { useGetStatusList } from "@app/services/common/hooks";
import { useGetDeliveryAuthList } from "@app/services/deliveryAuth/hooks";
import { DeliveryAuthFilters } from "@app/services/deliveryAuth/types";
import { Badge, Box, Card, Grid, Select, Text, TextInput } from "@mantine/core";
import { useDebouncedState } from "@mantine/hooks";
import { IconChevronRight, IconSearch } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import { Fragment } from "react/jsx-runtime";

const DeliveryAuth = () => {
  const navigate = useNavigate();

  const [filters, setFilters] = useDebouncedState<DeliveryAuthFilters & IFiltersPagination>(
    {
      endDate: "",
      startDate: "",
      staId: "",
      page: 1,
      search: "",
    },
    500,
  );

  const {
    data: deliveryList = {
      results: [],
      rowTotal: 0,
    },
    refetch,
  } = useGetDeliveryAuthList(
    {
      ...filters,
      perpage: 10,
    },
    dateValidation(filters.endDate, filters.startDate),
  );

  const {
    data: statusList = {
      results: [],
      rowTotal: 0,
    },
  } = useGetStatusList({
    staFunctionality: "complete_delivery_medicines",
  });

  const FiltersHeader = () => {
    return (
      <Grid align="flex-end" mb="lg" w="100%">
        <Grid.Col span={2}>
          <TextInput
            data-testid="startDate"
            label="Fecha desde"
            type="date"
            placeholder="Escribir..."
            onChange={({ target }) => {
              setFilters(state => ({
                ...state,
                startDate: target.value,
              }));
            }}
          />
        </Grid.Col>
        <Grid.Col span={2}>
          <TextInput
            data-testid="endDate"
            label="Fecha hasta"
            type="date"
            min={filters.startDate}
            placeholder="Escribir..."
            onChange={({ target }) => {
              setFilters(state => ({
                ...state,
                endDate: target.value,
              }));
            }}
          />
        </Grid.Col>
        <Grid.Col span={2}>
          <Select
            data-testid="site"
            label="Estado"
            data={formattedDataSelect(statusList.results, "description", "id")}
            searchable
            placeholder="Seleccionar..."
            onChange={value => {
              setFilters(state => ({
                ...state,
                staId: value ?? "",
              }));
            }}
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <TextField
            placeholder="Escribe aquí para buscar..."
            variant="filled"
            endIcon={<IconSearch className="pointer" onClick={() => refetch()} />}
            className="flex-grow-1 me-4"
            name="search"
            onChange={({ target }) =>
              setFilters(state => ({
                ...state,
                search: target.value,
              }))
            }
          />
        </Grid.Col>
      </Grid>
    );
  };

  const renderCards = () => {
    return (
      <Box pb={"sm"}>
        {deliveryList.results.length > 0 &&
          deliveryList.results.map(del => {
            const date = (del?.createdAt ?? "")?.split("T")[0]?.replaceAll("/", "-");
            const time = (del?.createdAt ?? "")?.split("T")[1]?.replaceAll("/", "-");

            return (
              <Card key={del.morId} mb={"md"} padding="lg" radius="md" __size="xl" className="">
                <Grid>
                  <Grid.Col span={9} className="flex gap-3">
                    <div>
                      <b className="secondary-color">Fecha de solicitud</b>
                    </div>
                    &nbsp; &nbsp; &nbsp;
                    <span>
                      {date} {formatTimeToAmPm(time)}
                    </span>
                  </Grid.Col>
                  <Grid.Col span={3} ta={"end"} className="flex justify-end">
                    <Text fz="xs" ml="xs" flex={1} fw={"inherit"}>
                      <Badge variant="light" color={del.status.fontColor}>
                        {del.status.description}
                      </Badge>
                    </Text>
                  </Grid.Col>
                </Grid>
                <div>
                  <hr />
                </div>
                <Grid>
                  <Grid.Col span={2.4}>
                    <Text fz="xs" ml="xs" flex={1} c={"brand-color"} fw={"bold"}>
                      Paciente
                    </Text>
                    <Text fz="xs" ml="xs" flex={1} fw={"inherit"}>
                      {del.patient.name}
                    </Text>
                  </Grid.Col>
                  <Grid.Col span={2.4}>
                    <Text fz="xs" ml="xs" flex={1} c={"brand-color"} fw={"bold"}>
                      Identificación
                    </Text>
                    <Text fz="xs" ml="xs" flex={1} fw={"inherit"}>
                      {del.patient.documentType} {del.patient.document}
                    </Text>
                  </Grid.Col>
                  <Grid.Col span={2.4}>
                    <Text fz="xs" ml="xs" flex={1} c={"brand-color"} fw={"bold"}>
                      Celular
                    </Text>
                    <Text fz="xs" ml="xs" flex={1} fw={"inherit"}>
                      {del.patient.phone}
                    </Text>
                  </Grid.Col>
                  <Grid.Col span={2.4}>
                    <Text fz="xs" ml="xs" flex={1} c={"brand-color"} fw={"bold"}>
                      Correo
                    </Text>
                    <Text fz="xs" ml="xs" flex={1} fw={"inherit"}>
                      {del.patient.email}
                    </Text>
                  </Grid.Col>
                  <Grid.Col span={2.4} className="self-end">
                    <Box className="flex justify-end ">
                      {del.status.value === "pending" && (
                        <IconChevronRight
                          style={{
                            width: 30,
                            height: 30,
                          }}
                          className="primary-color pointer"
                          onClick={() => {
                            navigate(routes.getDeliveryAuthDetail, {
                              state: {
                                data: del.morId,
                                dataAuth: del,
                              },
                            });
                          }}
                        />
                      )}
                    </Box>
                  </Grid.Col>
                  <Grid.Col span={12}>
                    <Text fz="xs" ml="xs" flex={1} c={"brand-color"} fw={"bold"}>
                      Motivo de solicitud
                    </Text>
                    <Text fz="xs" ml="xs" flex={1} fw={"inherit"}>
                      {del.rcdReason}
                    </Text>
                  </Grid.Col>
                </Grid>
              </Card>
            );
          })}
      </Box>
    );
  };

  const renderComponent = () => {
    return (
      <Fragment>
        {FiltersHeader()}
        {renderCards()}
      </Fragment>
    );
  };

  return (
    <Template title="Autorizar entrega completa de medicamentos">{renderComponent()}</Template>
  );
};

export default DeliveryAuth;
