import AppPagination from "@app/common/components/AppPagination";
import { Template } from "@app/common/components/Template";
import { TextField } from "@app/common/components/TextField";
import { IFiltersPagination } from "@app/common/types/http";
import { formattedDataSelect } from "@app/common/utils/utils";

import { useGetStatusList } from "@app/services/common/hooks";

import { useGetSupportConsultList } from "@app/services/SupportsConsult/hooks";
import { SupportConsultFilter } from "@app/services/SupportsConsult/types";
import { ActionIcon, Badge, Box, Collapse, Grid, Menu, Select, Table } from "@mantine/core";
import { useDebouncedState } from "@mantine/hooks";
import {
  IconChevronDown,
  IconChevronUp,
  IconCircleFilled,
  IconDotsVertical,
  IconPencil,
  IconSearch,
} from "@tabler/icons-react";
import { useState } from "react";
import { Fragment } from "react/jsx-runtime";
import DeliveryDetailDrawer from "./DeliveryDetailDrawer";

const PendingConsults = () => {
  //const navigate = useNavigate();
  const [filters, setFilters] = useDebouncedState<IFiltersPagination & SupportConsultFilter>(
    {
      contractId: null,
      corporateClient: null,
      startDate: "",
      endDate: "",
      search: "",
      status: null,
      page: 1,
    },
    500,
  );

  const {
    data: supportList = {
      results: [],
      rowTotal: 0,
    },
    refetch,
  } = useGetSupportConsultList({
    ...filters,
    perpage: 10,
  });

  const [FullDeliveryDrawer, setFullDeliveryDrawer] = useState({
    isOpen: false,
  });

  const {
    data: statusList = {
      results: [],
      rowTotal: 0,
    },
  } = useGetStatusList({
    staFunctionality: "dispensary_supports_reviews",
  });

  const valueTrue = true;

  const FiltersHeader = () => {
    return (
      <Grid align="flex-end" mb="lg" w="100%">
        <Grid.Col span={3}>
          <Select
            data-testid="status"
            label="Estado"
            searchable
            data={formattedDataSelect(statusList.results, "description", "id")}
            placeholder="Seleccionar..."
            onChange={value => {
              setFilters(state => ({
                ...state,
                status: value ?? "",
              }));
            }}
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <TextField
            placeholder="Escribe aquí para buscar..."
            variant="filled"
            endIcon={<IconSearch className="pointer" onClick={() => refetch()} />}
            className="flex-grow-1 me-4"
            name="search"
            onChange={({ target }) =>
              setFilters(state => ({
                ...state,
                search: target.value,
              }))
            }
          />
        </Grid.Col>
      </Grid>
    );
  };

  const renderRow = (option: (typeof supportList.results)[number]) => {
    return (
      <Fragment key={option.disId}>
        <Table.Tr>
          <Table.Td
            ta={"start"}
            w={"15rem"}
            className="flex gap-2 justify-center align-center items-center"
          >
            {valueTrue ? (
              <ActionIcon className="primary-color" variant="subtle">
                <IconChevronUp />
              </ActionIcon>
            ) : (
              <ActionIcon className="primary-color" variant="subtle">
                <IconChevronDown />
              </ActionIcon>
            )}
            <Box w={"100%"}>{option.patient}</Box>
          </Table.Td>

          <Table.Td ta={"center"}>{option.patientDocument}</Table.Td>
          <Table.Td>{option.service || "-"}</Table.Td>
          <Table.Td ta={"center"}>{option.service || "-"}</Table.Td>
          <Table.Td ta={"center"}>{option.createdAt.split("T")[0] || "-"}</Table.Td>
          <Table.Td ta={"center"}>{option.createdAt.split("T")[0] || "-"}</Table.Td>
          <Table.Td>{option.corporateClient || "-"}</Table.Td>
          <Table.Td>
            {" "}
            <Badge variant="light" color={option.status.fontColor} w={"100%"}>
              {option.status.description}
            </Badge>
          </Table.Td>
          <Table.Td ta={"center"}>
            <Menu position="bottom-end" withArrow data-testid="menu">
              <Menu.Target>
                <ActionIcon variant="subtle">
                  <IconDotsVertical />
                </ActionIcon>
              </Menu.Target>

              <Menu.Dropdown>
                <Menu.Item leftSection={<IconPencil />} data-testid="editSupport">
                  Entregar
                </Menu.Item>
                <Menu.Item
                  leftSection={
                    <IconCircleFilled
                      style={{
                        width: 10,
                      }}
                    />
                  }
                  data-testid="updateStatusSupport"
                >
                  Ordenamiento
                </Menu.Item>
                <Menu.Item
                  leftSection={<IconPencil />}
                  data-testid="editSupport"
                  onClick={() => {
                    setFullDeliveryDrawer(state => ({
                      ...state,
                      isOpen: true,
                    }));
                  }}
                >
                  Ver detalle
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </Table.Td>
        </Table.Tr>

        <Table.Tr>
          <Table.Td py={8} colSpan={12}>
            <Collapse in={valueTrue}>
              <Box px="45px">
                <table style={{ width: "60%" }}>
                  <thead>
                    <tr className="primary-color">
                      <th align="left">Medicamento</th>
                      <th align="center">Descripción</th>
                      <th align="center">Cantidad</th>
                      <th align="center">Estado</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr key={option.patient}>
                      <td>Acetaminofén</td>
                      <td align="center">Frasco 500mg</td>
                      <td align="center">120 und</td>
                      <td align="center">
                        {" "}
                        <Badge variant="transparent" color={option.status.fontColor} w={"100%"}>
                          {option.status.description}
                        </Badge>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Box>
            </Collapse>
          </Table.Td>
        </Table.Tr>
      </Fragment>
    );
  };

  const renderTable = () => {
    return (
      <Table className="biowel-table">
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Paciente</Table.Th>
            <Table.Th ta={"center"}>Identificación</Table.Th>
            <Table.Th ta={"start"}>Modalidad</Table.Th>
            <Table.Th ta={"center"}>Teléfono</Table.Th>
            <Table.Th ta={"center"}>Fecha orden</Table.Th>
            <Table.Th ta={"center"}>Fecha validez</Table.Th>
            <Table.Th ta={"start"}>Ordenado por</Table.Th>
            <Table.Th ta={"center"}>Estado</Table.Th>
            <Table.Th></Table.Th>
          </Table.Tr>
        </Table.Thead>

        <Table.Tbody>
          {supportList?.results?.length ? (
            supportList?.results?.map(renderRow)
          ) : (
            <Table.Tr>
              <Table.Td ta="center" colSpan={12}>
                No hay información para mostrar.
              </Table.Td>
            </Table.Tr>
          )}
        </Table.Tbody>
      </Table>
    );
  };

  return (
    <Template title="Consultas pendientes">
      {FiltersHeader()}
      {renderTable()}
      <DeliveryDetailDrawer
        onClose={() => {
          setFullDeliveryDrawer(state => ({
            ...state,
            isOpen: false,
          }));
        }}
        isOpen={FullDeliveryDrawer.isOpen}
      />
      <AppPagination
        total={supportList?.rowTotal || 0}
        value={filters.page || 1}
        onChange={value => setFilters(state => ({ ...state, page: value }))}
      />
    </Template>
  );
};

export default PendingConsults;
