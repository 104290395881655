import { confirmNotification, warningNotification } from "@app/common/components/notifications";
import { formattedDataSelect, UseFormattedDataSelect } from "@app/common/utils/utils";
import { GCidAccount } from "@app/config/globalsConst";
import {
  useGetCompanyClientList,
  useGetContractList,
  useGetExtensionList,
} from "@app/services/common/hooks";
import {
  useCreateSupport,
  useGetSupportParamsList,
  useUpdateSupport,
} from "@app/services/supportParams/hooks";
import { Extension, Support, SupportParamsFilter } from "@app/services/supportParams/types";
import { generateId } from "@app/utils/generateId";
import {
  ActionIcon,
  Badge,
  Box,
  Button,
  Card,
  Grid,
  Menu,
  MultiSelect,
  Select,
  TextInput,
  Title,
  Tooltip,
} from "@mantine/core";
import {
  IconChevronLeft,
  IconCircleCheck,
  IconCircleFilled,
  IconCirclePlus,
  IconCircleX,
  IconDotsVertical,
  IconPencil,
  IconTrash,
} from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Fragment } from "react/jsx-runtime";

const HandleUpdateSupportParameter = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [filters, setFilters] = useState<SupportParamsFilter>({
    corporateClient: null,
    contractId: null,
    trigger: 0,
  });
  const action = location.state.action;
  const { contractId, corporateClient } = location.state.data ?? {};
  const [supportListUpdated, setSupportListUpdated] = useState<Support[]>([]);

  const {
    data: supportList = {
      results: [],
      rowTotal: 0,
    },
  } = useGetSupportParamsList(
    {
      ...filters,
    },
    filters.contractId !== null,
  );

  const {
    data: companyList = {
      results: [],
      rowTotal: 0,
    },
  } = useGetCompanyClientList({
    status: "enabled",
    eaccount: GCidAccount(),
  });

  const {
    data: extensionList = {
      results: [],
      rowTotal: 0,
    },
  } = useGetExtensionList();

  const {
    data: contractList = {
      results: [],
      rowTotal: 0,
    },
  } = useGetContractList(
    {
      corporateClient: Number(filters.corporateClient),
      slim: 1,
    },
    filters.corporateClient !== null,
  );

  const isDisabledField = (support: Support) => {
    if (action === "create") {
      const isDisabled = support.id;
      return Boolean(isDisabled);
    } else {
      return support.isEditing ? false : true;
    }
  };

  useEffect(() => {
    if (action === "edit") {
      setFilters({
        contractId,
        corporateClient,
      });
    }
  }, [contractId, corporateClient, action]);

  useEffect(() => {
    if (supportList?.results?.at(0)?.supports) {
      setSupportListUpdated(supportList.results.at(0)?.supports ?? []);
    } else {
      setSupportListUpdated([]);
    }
  }, [supportList.results, setSupportListUpdated]);

  const filterSection = () => {
    return (
      <>
        <div className="searchSection">
          <Box>
            <Box className="flex gap-2">
              <Title
                pt={"xl"}
                mt={"sm"}
                pb={"xs"}
                px={"lg"}
                size={"h4"}
                w={"100%"}
                c="secondary-color"
                fw={"bold"}
                ta={"start"}
                className="flex justify-center"
              >
                Empresa cliente
              </Title>
            </Box>
            <Box px={"xl"}>
              <div className="cardSearch">
                <Select
                  data-testid="insurance"
                  clearable
                  disabled={action === "edit"}
                  label="Empresa cliente"
                  data={UseFormattedDataSelect(companyList.results, "name", "id")}
                  searchable
                  value={String(filters.corporateClient)}
                  placeholder="Seleccionar..."
                  onChange={(_, value) => {
                    setFilters(state => ({
                      ...state,
                      corporateClient: value?.value ?? "",
                      contractId: "",
                    }));
                    setSupportListUpdated([]);
                  }}
                />
                <TextInput
                  label="NIT"
                  placeholder="Escribir..."
                  disabled
                  value="Pa la proxima..."
                />
                <Select
                  data-testid="insurance"
                  label="Contrato Dispensación"
                  disabled={action === "edit"}
                  data={UseFormattedDataSelect(contractList.results, "contractName", "id")}
                  searchable
                  value={String(filters.contractId)}
                  placeholder="Seleccionar..."
                  onChange={(_, value) => {
                    setFilters(state => ({
                      ...state,
                      contractId: value?.value ?? "",
                    }));
                    setSupportListUpdated([]);
                  }}
                />
              </div>
            </Box>
          </Box>
        </div>
      </>
    );
  };

  const addSupport = () => {
    setSupportListUpdated(state => [
      ...state,
      {
        filename: "",
        extensions: [],
        deletedAt: null,
        generateId: generateId(),
      },
    ]);
  };

  const handleChangeSupport = (support: Support, field: string, value: string | string[]) => {
    const editSupport = supportListUpdated.map(item =>
      (item.id ?? item.generateId) === (support.id ?? support.generateId)
        ? { ...item, [field]: value }
        : item,
    );

    setSupportListUpdated(editSupport);
  };

  const valueMultiSelect = (support: Support) => {
    const isArrayOfJSON =
      Array.isArray(support.extensions) &&
      support.extensions.every(item => typeof item === "object" && item !== null);

    if (support.id && isArrayOfJSON) {
      const valueMulti: string[] = (support.extensions as Extension[]).map(opt =>
        opt?.id.toString(),
      );
      return valueMulti as string[];
    } else {
      return support.extensions as string[];
    }
  };
  const createSupportRequest = useCreateSupport(async () => {
    const doAfter = () => {
      setFilters(state => ({
        ...state,
        trigger: (state.trigger ?? 0) + 1,
      }));
    };

    await doAfter();
  });
  const updateSupportRequest = useUpdateSupport(async () => {
    const doAfter = () => {
      setFilters(state => ({
        ...state,
        trigger: (state.trigger ?? 0) + 1,
      }));
    };

    await doAfter();
  });

  const deleteSupport = (support: Support) => {
    const filter = supportListUpdated.filter(x => x.generateId !== support.generateId);
    setSupportListUpdated(filter);
  };

  const onSubmit = async () => {
    const list = supportListUpdated.filter(item => item.generateId);
    const validationFileName = list.some(item => item.filename === "");
    const validationExtensions = list.some(item => item.extensions.length === 0);

    if (validationFileName || validationExtensions) {
      warningNotification("Verifique que todos los campos se encuentren llenos");
    } else {
      await createSupportRequest.mutateAsync({
        contractId: Number(filters.contractId ?? ""),
        supports: list.map(x => ({
          ...x,
          extensions: x.extensions.map(Number),
        })),
      });
    }
  };

  const handleEditFunction = async (support: Support, isEditing: boolean, isDisabled?: string) => {
    const editSupport = supportListUpdated.map(item =>
      item.id === support.id ? { ...item, isEditing } : item,
    );
    setSupportListUpdated(editSupport);

    if (!isEditing) {
      const list = supportListUpdated.find(item => item.id === support.id);
      if (list) {
        await updateSupportRequest.mutateAsync({
          action: isDisabled ?? "",
          extensions: valueMultiSelect(support).map(Number),
          filename: list.filename,
          id: Number(support.id),
        });
      }
    }
  };

  const handleCancelFunction = (support: Support) => {
    const findSupport: Support = supportList.results
      .at(0)
      ?.supports.find(item => item.id === support.id) ?? {
      filename: "",
      extensions: [],
    };

    const editSupport: Support[] = supportListUpdated.map(item =>
      item.id === support.id ? findSupport : item,
    );
    if (editSupport) {
      setSupportListUpdated(editSupport);
    }
  };
  const supportListSection = () => {
    return (
      <Box w={"100%"}>
        <Title
          size={"h3"}
          w={"100%"}
          c="brand-color"
          pb={"sm"}
          fw={"bold"}
          ta={"start"}
          className="flex "
        >
          <div
            data-testid="goback1"
            className="flex items-center pointer"
            onClick={() => navigate(-1)}
          >
            <Tooltip label="Regresar" withArrow position="right">
              <IconChevronLeft
                style={{ height: 30, width: 30 }}
                className="primary-color pointer"
              />
            </Tooltip>
          </div>
          {action === "create" ? "Crear" : "Editar"} parametrización de soportes
        </Title>
        <Box py={"xs"}>
          <Grid>
            <Grid.Col span={10}>
              <b className="primary-color">Asesores</b>
            </Grid.Col>
            <Grid.Col span={2} className="flex justify-end">
              {action === "create" && (
                <Button
                  variant="transparent"
                  data-testid="addSupport"
                  rightSection={<IconCirclePlus />}
                  onClick={addSupport}
                >
                  Agregar
                </Button>
              )}
            </Grid.Col>
          </Grid>
        </Box>

        {supportListUpdated.length > 0 &&
          supportListUpdated.map(support => {
            const isDisabled = support.deletedAt !== null ? true : false;
            return (
              <Card mb={"sm"} key={support.filename} data-testid="holaa">
                <Grid>
                  {support.id && (
                    <>
                      <Grid.Col span={10}>
                        {isDisabled ? (
                          <Box fz={12}>
                            <span>
                              Inhabilitado por&nbsp;
                              {support.deletedBy} | {support.deletedAt}
                            </span>
                          </Box>
                        ) : (
                          <Box fz={12}>
                            {support.updatedBy !== "" && support.updatedBy !== null ? (
                              <div>
                                Editado por {support.updatedBy} | {support.updatedAt}
                              </div>
                            ) : (
                              <div>
                                Creación: {support.createdAt} | {support.createdBy}
                              </div>
                            )}
                          </Box>
                        )}
                      </Grid.Col>
                      <Grid.Col span={2} className="flex justify-end">
                        <Badge variant="light" color={isDisabled ? "red" : "green"}>
                          {isDisabled ? "Inhabilitado" : "Habilitado"}
                        </Badge>
                      </Grid.Col>
                    </>
                  )}
                  <Grid.Col span={5.5}>
                    <TextInput
                      label="Documento de cargue asesor"
                      placeholder="Escribir..."
                      data-testid={support.filename}
                      autoFocus
                      disabled={isDisabledField(support)}
                      defaultValue={support.filename}
                      onChange={({ target }) =>
                        handleChangeSupport(support, "filename", target.value)
                      }
                    />
                  </Grid.Col>
                  <Grid.Col span={5.5}>
                    <MultiSelect
                      clearable
                      data-testid="insurance"
                      label="Tipo de documento"
                      data={formattedDataSelect(
                        extensionList.results,
                        "extension",
                        "id",
                        [],
                        false,
                      )}
                      searchable
                      disabled={isDisabledField(support)}
                      defaultValue={valueMultiSelect(support)}
                      onChange={e => handleChangeSupport(support, "extensions", e)}
                    />
                  </Grid.Col>
                  <Grid.Col span={1}>
                    <Box
                      className="flex justify-center items-end full"
                      p={action === "create" ? "xs" : "0"}
                    >
                      {action === "create" ? (
                        <div
                          className="pointer"
                          data-testid="deleteSupport"
                          onClick={() => {
                            deleteSupport(support);
                          }}
                        >
                          {!isDisabledField(support) && <IconTrash />}
                        </div>
                      ) : support.isEditing ? (
                        <Box pb={"0.2rem"} className="flex justify-around">
                          <Tooltip label={"Guardar"}>
                            <IconCircleCheck
                              color="#7bc62d"
                              style={{
                                width: 25,
                                height: 25,
                              }}
                              data-testid="saveSupport"
                              className="pointer"
                              onClick={() => handleEditFunction(support, false, "")}
                            />
                          </Tooltip>
                          <Tooltip label={"Cancelar"}>
                            <IconCircleX
                              color="#ff2825"
                              style={{
                                width: 25,
                                height: 25,
                              }}
                              data-testid="cancelSupport"
                              className="pointer"
                              onClick={() => handleCancelFunction(support)}
                            />
                          </Tooltip>
                        </Box>
                      ) : (
                        <Menu position="bottom-end" withArrow data-testid="menu">
                          <Menu.Target>
                            <ActionIcon variant="subtle">
                              <IconDotsVertical />
                            </ActionIcon>
                          </Menu.Target>

                          <Menu.Dropdown>
                            <Menu.Item
                              leftSection={<IconPencil />}
                              data-testid="editSupport"
                              onClick={() => handleEditFunction(support, true)}
                            >
                              Editar
                            </Menu.Item>
                            <Menu.Item
                              color={isDisabled ? "green" : "red"}
                              leftSection={
                                <IconCircleFilled
                                  style={{
                                    width: 10,
                                  }}
                                />
                              }
                              data-testid="updateStatusSupport"
                              onClick={() => {
                                confirmNotification(
                                  `Se ${
                                    isDisabled ? "habilitará" : "inhabilitará"
                                  } el soporte: ${support.filename}`,
                                  "¿Está seguro?",
                                  () => {
                                    handleEditFunction(
                                      support,
                                      false,
                                      isDisabled ? "enabled" : "disabled",
                                    );
                                  },
                                );
                              }}
                            >
                              {isDisabled ? "Habilitar" : "Inhabilitar"}
                            </Menu.Item>
                          </Menu.Dropdown>
                        </Menu>
                      )}
                    </Box>
                  </Grid.Col>
                </Grid>
              </Card>
            );
          })}
      </Box>
    );
  };
  const renderComponent = () => {
    return (
      <Fragment>
        <div className="bodyOrder">
          {filterSection()}
          <Box className="renderSection main-container">
            {filters.contractId && filters.corporateClient && (
              <>
                {supportListSection()}
                <Box className="flex justify-end gap-3" w={"100%"}>
                  <Button variant="outline" data-testid="goBack2" onClick={() => navigate(-1)}>
                    Cancelar
                  </Button>
                  {action === "create" &&
                    supportListUpdated.filter(item => item.generateId).length > 0 && (
                      <Button variant="filled" data-testid="saveCreateSupport" onClick={onSubmit}>
                        Guardar
                      </Button>
                    )}
                </Box>
              </>
            )}
          </Box>
        </div>
      </Fragment>
    );
  };

  return renderComponent();
};

export default HandleUpdateSupportParameter;
