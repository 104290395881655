import { Box } from "@mantine/core";
import { Template } from "./Template";

interface TemplateProps extends React.HTMLAttributes<HTMLElement> {
  title: string;
  subtitle?: string;
  goBackButton: () => void;
  url: string;
  urlType: "base64" | "url";
  showContainer?: boolean;
  showBgColor?: boolean;
  showBgColorRight?: boolean;
}

const DownloadTemplate = ({
  title,
  goBackButton,
  children,
  url,
  subtitle,
  urlType,
  showContainer = true,
  showBgColor = true,
  showBgColorRight = true,
  ...rest
}: TemplateProps) => {
  const iframeValue = urlType === "url" ? url : `data:application/pdf;base64,${url}`;

  return (
    <Box
      className={showContainer ? "main-container" : ""}
      w={"100%"}
      bg={showBgColor ? "#e6f8fa80" : "#fff"}
      {...rest}
    >
      <Template title={title} subtitle={subtitle} goBackButton={goBackButton} className="full">
        <Box className={`full flex gap-3   ${!children && "justify-center"}`} pt={"md"}>
          <Box w={children ? "70%" : "80%"} px={children ? "" : "lg"}>
            {url && (
              <iframe
                title="document"
                src={iframeValue}
                style={{ width: "100%", height: "100%" }}
                frameBorder="0"
              />
            )}
          </Box>
          {children && (
            <Box w={"30%"} h={"100hv"} bg={showBgColorRight ? "" : "#fff"}>
              {children}
            </Box>
          )}
        </Box>
      </Template>
    </Box>
  );
};

export default DownloadTemplate;
