import { IFiltersPagination, IResponse } from "@app/common/types/http";
import { HTTP_ADMIN, HTTP_DISPENSARY, HTTP_MEDICAL, HTTP_PAYROLL } from "@app/config/axios";
import {
  bankEntitiesResponse,
  DeliveryPayload,
  LotsScannerResponse,
  OrderListFilters,
  OrderListPatient,
  OrderListResponse,
  paymentListResponse,
  pricePatientPayload,
  reminderDispensationPayload,
} from "./types";

export async function listOrdersService(
  filters: OrderListFilters & IFiltersPagination,
  token: string,
) {
  const res = await HTTP_DISPENSARY(token).get<IResponse<OrderListPatient[]>>("/ordering/", {
    params: filters,
  });
  return res.data;
}

export async function orderDetail(filters: { orderId: number }, token: string) {
  const res = await HTTP_DISPENSARY(token).get<IResponse<OrderListResponse>>("/dispensaries/", {
    params: filters,
  });
  return res.data;
}

export async function lotsScanner(filters: { orderId: number; barcode: number }, token: string) {
  const res = await HTTP_DISPENSARY(token).get<IResponse<LotsScannerResponse>>("/lots/", {
    params: filters,
  });
  return res.data;
}

export async function paymentListService(filters: { eaccount: number }, token: string) {
  const res = await HTTP_MEDICAL(token).get<IResponse<paymentListResponse[]>>(
    "/admissions/depositType/",
    {
      params: filters,
    },
  );
  return res.data;
}
export async function bankListService(filters: { eaccount: number }, token: string) {
  const res = await HTTP_ADMIN(token).get<IResponse<bankEntitiesResponse[]>>(
    "/admissions/bankingEntities",
    {
      params: filters,
    },
  );
  return res.data;
}
export async function documentListService(token: string) {
  const res = await HTTP_PAYROLL(token).get<IResponse<{ id: number; description: string }[]>>(
    "/document_types/",
    {
      params: {},
    },
  );
  return res.data;
}

export async function pricePatientService(payload: pricePatientPayload, token: string) {
  const axiosInstance = HTTP_DISPENSARY(token);
  const response = await axiosInstance.post<IResponse>(`/dispensaries/dispensary-price`, payload);
  return response.data;
}
export async function deliveryDispenseService(payload: DeliveryPayload, token: string) {
  const axiosInstance = HTTP_DISPENSARY(token);
  const response = await axiosInstance.post<IResponse>(`/dispensaries/`, payload);
  return response.data;
}

export async function deliveryPdfDetail(filters: { disId: number }, token: string) {
  const res = await HTTP_DISPENSARY(token).get<IResponse<{ base64: string }>>("/dispensaries/pdf", {
    params: filters,
  });
  return res.data;
}

export async function deliveryReminderService(payload: reminderDispensationPayload, token: string) {
  const axiosInstance = HTTP_DISPENSARY(token);
  const response = await axiosInstance.post<IResponse>(
    `/dispensaries/${payload.id}/reminder`,
    payload,
  );
  return response.data;
}
