import { commonFilters, IFiltersPagination } from "@app/common/types/http";
import { useAuthentication } from "@app/store/useAuthtentication";
import { useQuery } from "@tanstack/react-query";
import {
  bankListService,
  companyClientService,
  companyListService,
  companyTypeService,
  contractListService,
  depositTypeService,
  doctorListService,
  documentTypeService,
  extensionListService,
  franchiseService,
  listPatientService,
  listSitesService,
  listSitesToStartService,
  statusListService,
} from "./common";

export function useGetCompanyTypeList(filters: commonFilters, enabled = true) {
  const token = useAuthentication(state => state.auth.dataBiowel.token);
  const query = useQuery({
    enabled,
    gcTime: 0,
    queryKey: ["companyTypeList"],
    queryFn: async () => await companyTypeService(filters, token),
  });
  return query;
}
export function useGetPatientList(
  filters: { orderByType?: string } & IFiltersPagination,
  enabled = true,
) {
  const token = useAuthentication(state => state.auth.dataBiowel.token);

  const query = useQuery({
    enabled,
    gcTime: 0,
    queryKey: ["patients", filters.page, filters.search, filters.orderByType],
    queryFn: async () => await listPatientService(filters, token),
  });

  return query;
}
export function useGetCompanyClientList(
  filters: { contractCompanyType?: string } & commonFilters,
  enabled = true,
) {
  const token = useAuthentication(state => state.auth.dataBiowel.token);

  const query = useQuery({
    enabled,
    gcTime: 0,
    queryKey: ["companyClients"],
    queryFn: async () => await companyClientService(filters, token),
  });

  return query;
}
export function useGetSiteList(filters: { modality?: string } & commonFilters, enabled = true) {
  const token = useAuthentication(state => state.auth.dataBiowel.token);

  const query = useQuery({
    enabled,
    gcTime: 0,
    queryKey: ["sites"],
    queryFn: async () => await listSitesService(filters, token),
  });

  return query;
}
export function useGetDoctorList(filters: commonFilters, enabled = true) {
  const token = useAuthentication(state => state.auth.dataBiowel.token);

  const query = useQuery({
    enabled,
    gcTime: 0,
    queryKey: ["doctors"],
    queryFn: async () => await doctorListService(filters, token),
  });

  return query;
}
export function useGetStatusList(filters: { staFunctionality: string }, enabled = true) {
  const token = useAuthentication(state => state.auth.dataBiowel.token);
  const query = useQuery({
    enabled,
    gcTime: 0,
    queryKey: ["status"],
    queryFn: async () => await statusListService(filters, token),
  });

  return query;
}

export function useGetDocumentTypes() {
  const token = useAuthentication(state => state.auth.dataBiowel.token);
  const query = useQuery({
    gcTime: 0,
    queryKey: ["documentTypes"],
    queryFn: async () => await documentTypeService(token),
  });

  return query;
}
export function useGetCompanyList() {
  const token = useAuthentication(state => state.auth.dataBiowel.token);
  const eaccount = useAuthentication(store => store.auth.dataBiowel.idAccount);
  const query = useQuery({
    gcTime: 0,
    queryKey: ["companyList"],
    queryFn: async () => await companyListService(token, eaccount),
  });

  return query;
}
export function useGetContractList(
  filters: {
    slim: number;
    corporateClient: number;
  },
  enabled: boolean,
) {
  const token = useAuthentication(state => state.auth.dataBiowel.token);
  const query = useQuery({
    gcTime: 0,
    enabled,
    queryKey: ["companyList", filters.corporateClient],
    queryFn: async () => await contractListService(filters, token),
  });

  return query;
}

export function useGetExtensionList() {
  const token = useAuthentication(state => state.auth.dataBiowel.token);
  const query = useQuery({
    gcTime: 0,
    queryKey: ["extensions"],
    queryFn: async () => await extensionListService(token),
  });

  return query;
}

export function useGetSiteListToStart(
  filters: { userId?: number } & commonFilters,
  enabled = true,
) {
  const token = useAuthentication(state => state.auth.dataBiowel.token);

  const query = useQuery({
    enabled,
    gcTime: 0,
    queryKey: ["sitesToStart"],
    queryFn: async () => await listSitesToStartService(filters, token),
  });

  return query;
}

export function useGetBankEntities(filters: { eaccount: number }, enabled = true) {
  const token = useAuthentication(state => state.auth.dataBiowel.token);

  const query = useQuery({
    enabled,
    queryKey: ["bankList", filters.eaccount],
    queryFn: async () => await bankListService(filters, token),
  });

  return query;
}
export function useGetDepositType(filters: { eaccount: number }, enabled = true) {
  const token = useAuthentication(state => state.auth.dataBiowel.token);

  const query = useQuery({
    enabled,
    queryKey: ["depositList", filters.eaccount],
    queryFn: async () => await depositTypeService(filters, token),
  });

  return query;
}
export function useGetFranchise(filters: { eaccount: number }, enabled = true) {
  const token = useAuthentication(state => state.auth.dataBiowel.token);

  const query = useQuery({
    enabled,
    queryKey: ["franchiseList", filters.eaccount],
    queryFn: async () => await franchiseService(filters, token),
  });

  return query;
}
