/* eslint-disable @typescript-eslint/no-explicit-any */
import { successNotification, warningNotification } from "@app/common/components/notifications";
import { IFiltersPagination, IResponse } from "@app/common/types/http";
import { useAuthentication } from "@app/store/useAuthtentication";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  bankListService,
  deliveryDispenseService,
  deliveryPdfDetail,
  deliveryReminderService,
  documentListService,
  listOrdersService,
  lotsScanner,
  orderDetail,
  paymentListService,
  pricePatientService,
} from "./drugDelivery";
import {
  DeliveryPayload,
  OrderListFilters,
  pricePatientPayload,
  reminderDispensationPayload,
} from "./types";

export function useGetOrdersList(filters: OrderListFilters & IFiltersPagination, enabled = true) {
  const token = useAuthentication(state => state.auth.dataBiowel.token);

  const query = useQuery({
    enabled,
    gcTime: 0,
    queryKey: [
      "orders",
      filters.page,
      filters.startDate,
      filters.endDate,
      filters.site,
      filters.doctor,
      filters.corporateClient,
    ],
    queryFn: async () => await listOrdersService(filters, token),
  });

  return query;
}
export function useGetOrderDetail(filters: { orderId: number }, enabled = true) {
  const token = useAuthentication(state => state.auth.dataBiowel.token);

  const query = useQuery({
    enabled,
    gcTime: 0,
    queryKey: ["ordersDetail", filters.orderId],
    queryFn: async () => await orderDetail(filters, token),
  });

  return query;
}
export function useGetLotsScanner(filters: { orderId: number; barcode: number }, enabled = true) {
  const token = useAuthentication(state => state.auth.dataBiowel.token);

  const query = useQuery({
    enabled,
    gcTime: 0,
    queryKey: ["lotsScanner", filters.barcode],
    queryFn: async () => await lotsScanner(filters, token),
  });

  return query;
}
export function useGetPaymentList(filters: { eaccount: number }, enabled = true) {
  const token = useAuthentication(state => state.auth.dataBiowel.token);

  const query = useQuery({
    enabled,
    gcTime: 0,
    queryKey: ["paymentList", filters.eaccount],
    queryFn: async () => await paymentListService(filters, token),
  });

  return query;
}
export function useGetBankList(filters: { eaccount: number }, enabled = true) {
  const token = useAuthentication(state => state.auth.dataBiowel.token);

  const query = useQuery({
    enabled,
    gcTime: 0,
    queryKey: ["bankList", filters.eaccount],
    queryFn: async () => await bankListService(filters, token),
  });

  return query;
}
export function useGetDocumentList(enabled = true) {
  const token = useAuthentication(state => state.auth.dataBiowel.token);

  const query = useQuery({
    enabled,
    gcTime: 0,
    queryKey: ["documentList"],
    queryFn: async () => await documentListService(token),
  });

  return query;
}

export function useGetPatientPrice(
  onSuccess = async (res: IResponse) => {
    res;
  },
) {
  const token = useAuthentication(state => state.auth.dataBiowel.token);
  const mutation = useMutation({
    mutationFn: async (payload: pricePatientPayload) => await pricePatientService(payload, token),
    onSuccess: async res => {
      await onSuccess(res);
    },
    onError: async (error: any) => {
      warningNotification(error.response.data.error, "Intenta de nuevo");
    },
  });

  return mutation;
}

export function useDeliveryDispense(
  onSuccess = async (res: IResponse) => {
    res;
  },
) {
  const token = useAuthentication(state => state.auth.dataBiowel.token);
  const mutation = useMutation({
    mutationFn: async (payload: DeliveryPayload) => await deliveryDispenseService(payload, token),
    onSuccess: async res => {
      successNotification(res.message, "Realizado con éxito");
      await onSuccess(res);
    },
    onError: async (error: any) => {
      warningNotification(error.response.data.error, "Intenta de nuevo");
    },
  });

  return mutation;
}

export function useGetPdfDetail(filters: { disId: number }, enabled = true) {
  const token = useAuthentication(state => state.auth.dataBiowel.token);

  const query = useQuery({
    enabled,
    gcTime: 0,
    queryKey: ["dispensaryDetailPdf"],
    queryFn: async () => await deliveryPdfDetail(filters, token),
  });

  return query;
}

export function useDeliveryReminder(
  onSuccess = async (res: IResponse) => {
    res;
  },
) {
  const token = useAuthentication(state => state.auth.dataBiowel.token);
  const mutation = useMutation({
    mutationFn: async (payload: reminderDispensationPayload) =>
      await deliveryReminderService(payload, token),
    onSuccess: async res => {
      successNotification(res.message, "Realizado con éxito");
      await onSuccess(res);
    },
    onError: async (error: any) => {
      warningNotification(error.response.data.error, "Intenta de nuevo");
    },
  });

  return mutation;
}
